<template>
  <v-lazy transition="fade-transition">
    <v-card class="mx-auto mb-2" :class="{ 'card-selected': selected }">
      <v-card v-if="showImage && value.type == 'IMAGE'" v-on="{ click: selectable ? toggleItem : () => { $emit('imageClick', value) } }" flat>
        <v-img :src="$eod.getImageUrl(value.key)" lazy-src="@/assets/img/logo-full-red.svg" aspect-ratio="1.4"
          contain></v-img>
      </v-card>
      <v-card flat v-else-if="value.type == 'DOCUMENT'" :href="$eod.getImageUrl(this.value.key)">
        <v-card-title class="text-body-2">{{ value.name }}</v-card-title>
      </v-card>
      <v-card v-else flat v-on="{ click: selectable ? toggleItem : () => { $emit('click', value) } }">
        <v-card-title>
          <div v-if="value.createdAt"><v-icon small
              left>mdi-clock-outline</v-icon>{{ $moment(value.createdAt).format('DD/MM/YYYY') }}</div>
        </v-card-title>
      </v-card>
      <v-card-actions>
        <div v-if="showImage" class="text-caption">
          <div v-if="value.createdAt"><v-icon small
              left>mdi-clock-outline</v-icon>{{ $moment(value.createdAt).format('DD/MM/YYYY') }}</div>
        </div>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
        <template v-if="!selectable && !readonly">
          <v-btn icon small @click="$emit('favorite', value)">
            <v-icon v-if="value.isFavorite" small>mdi-star</v-icon>
            <v-icon v-else small>mdi-star-outline</v-icon>
          </v-btn>
          <v-btn icon small @click="$emit('edit', value)" v-if="!hideEdit">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn icon small @click="$emit('delete', value)">
            <v-icon small color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>

      </v-card-actions>
      <v-card-actions class="flex-wrap" v-if="!hideTags">
        <v-chip @click="$emit('tagClick', tag, true)" v-bind="tagAttrs(tag)" v-for="tag in system_tags" :key="tag.value"
          class="mr-2 mb-2" small><v-icon class="mr-2" small v-if="tag.icon"
            v-html="tag.icon"></v-icon>{{ tag.name }}</v-chip>
        <v-chip class="mr-2 mb-2" small v-for="tag in value.tags" :key="tag.id"><v-icon small
            class="mr-2">mdi-tag-outline</v-icon>{{ tag.name }}</v-chip>
      </v-card-actions>
    </v-card>
  </v-lazy>
</template>
<script>
export default {
  props: {
    value: Object,
    filters: Array,
    selectable: Boolean,
    showImage: {
      type: Boolean,
      default: true
    },
    hideTags: {
      type: Boolean,
      default: false
    },
    hideEdit: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    system_tag: null,
    product_tag: null,
    selected: false,
    modules: require('./../classes/settings').modules
  }),
  mounted() {
    this.linkTags();
  },
  watch: {
    value(val) {
      this.linkTags();
    },
  },
  computed: {
    system_tags() {
      const checked = [];
      let tags = [];
      for (const endpoint in this.modules) {
        if (Object.hasOwnProperty.call(this.modules, endpoint)) {
          const my_module = this.modules[endpoint];
          if (this.value[my_module.item] && this.value[my_module.item].id && !checked.includes(my_module.item)) {
            let name = this.value[my_module.item].name;
            if(this.value[my_module.item].getFullName){
              name = this.value[my_module.item].getFullName();
            }

            tags.push({
              icon: my_module.icon,
              filter: my_module.key,
              value: this.value[my_module.item].id,
              name: name,
            });
          } else if (this.value[endpoint] && this.value[endpoint].length > 0 && !checked.includes(endpoint)) {
            for (let i = 0; i < this.value[endpoint].length; i++) {
              const item = this.value[endpoint][i];
              tags.push({
                icon: my_module.icon,
                filter: my_module.key,
                value: item.id,
                name: item.name,
              });
            }
          }
          checked.push(my_module.item);
          checked.push(endpoint);
        }
      }

      return tags;

    }
  },
  methods: {
    toggleItem() {
      this.selected = !this.selected;
      this.$emit('selectionChanged', this.value, this.selected);
    },
    tagAttrs(tag) {
      if (this.filters) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.config.filter == tag.filter && filter.result.id == tag.value) {
            return {
              color: 'primary',
            };
          }
        }
      }

      return {
        color: 'grey darken-1',
        outlined: true
      };
    },
    linkTags() {
      this.system_tag = [];
      if (this.value.task) {
        this.system_tag = this.value.task;
      } else if (this.value.project) {
        this.system_tag = this.value.project;
      } else if (this.value.company) {
        this.system_tag = this.value.company;
      }

      this.product_tag = [];
      if (this.value.productType) {
        this.product_tag = this.value.productType;
      }
      if (this.value.product) {
        this.product_tag = this.value.product;
      }
    }
  }
}
</script>