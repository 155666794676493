module.exports = {
    routes: [{
        path: '/lists',
        name: 'lists',
        meta:{
            permissions:['lists.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/lists/detail/:id?',
        name: 'list',
        meta:{
            permissions:['lists.update','lists.create']
        },
        component: () => import('./views/detail.vue')
    },],
    search: [{
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'settings': {
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'name'
        },
    ],
    itemObject: require('@/eodvuecomponents/src/models/list').default,
    detail: {
        route: 'list',
        endpoint: 'list',
        objectName: 'List',
        fields: ['id', 'name', 'description', 'isActive', 'isSystem', 'itemType', 'fields{name isActive}', 'listItems{id name description isDeleted isActive low high color stepSize threshold fields{name isActive translations{locale value isActive}}}'],
        tabs:[
            {
                tab: 'general',
                text: 'Algemeen'
            }, {
                tab: 'fields',
                text: 'Velden',
            }
        ]
    },
    index: {
        route: 'lists',
        endpoint: 'lists',
        fields: ['id', 'name', 'description', 'listItems{id}', 'createdAt', 'updatedAt', 'isActive'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Items',
                value: item => {
                    if (!item.listItems) {
                        return 0;
                    }
                    return item.listItems.length;
                },
                width: 50,
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 80,
                sortable: false,
                value:'isActive',
                component: () => import('./columns/switch.vue')
            },
            {
                width: 60,
                sortable: false,
                component: () => import('./columns/actions.vue')
            }
        ],
        tabs: [{
            path: 'lists',
            text: 'Overzicht'
        }],
    }
}