<template>
    <div>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <template v-if="!loading && company">
            <v-card-title>
                <v-tabs fixed-tabs v-model="tab">
                    <v-tab href="#general"><v-icon>mdi-information-variant</v-icon></v-tab>
                    <v-tab href="#location"><v-icon>mdi-map-marker-outline</v-icon></v-tab>
                    <v-tab href="#contacts"><v-icon>mdi-account-multiple</v-icon></v-tab>
                </v-tabs>
            </v-card-title>
            <v-card-text :style="isPopup?'height:240px;overflow-y:auto;':''">
            <v-tabs-items v-model="tab">
                <v-tab-item value="general" transition="transition-fade">
                    <v-card flat outlined>
                        <v-card-subtitle class="grey lighten-4 py-2">Algemeen</v-card-subtitle>
                        <v-card-text class="mt-2">
                            <div v-if="company.description && company.description != ''"><strong>Omschrijving:</strong> {{company.description}}</div>
                            <div v-if="company.vatNumber && company.vatNumber != ''"><strong>BTW-nummer:</strong> {{company.vatNumber}}</div>
                            <div v-if="company.division"><strong>Divisie:</strong> {{company.division.name}}</div>
                            <div v-if="company.department"><strong>Departement:</strong> {{company.department.name}}</div>
                        </v-card-text>
                    </v-card>
                    <v-card flat outlined v-if="sharepoint" class="mt-2">
                        <v-card-subtitle class="grey lighten-4 py-2">Koppelingen</v-card-subtitle>
                        <v-card-text class="mt-2">
                            <v-row class="mt-2">
                                <v-col>
                                    <a target="_blank" style="max-width:50px;display:block;" :href="sharepoint"><v-img :src="require('@/assets/img/integrations/sharepoint.svg')" contain/></a>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <eod-tags :value="company.tags" small></eod-tags>
                </v-tab-item>
                <v-tab-item value="location" transition="transition-fade">
                    <eod-locations-info :value="company.locations" outlined flat hide-title></eod-locations-info>
                </v-tab-item>
                <v-tab-item value="contacts" transition="transition-fade">
                    <eod-contacts-info :value="company.users" outlined flat hide-title dense></eod-contacts-info>
                </v-tab-item>
            </v-tabs-items>
            </v-card-text>
            </template>
        
     </div>
</template>
<script>
import eodLocationsInfo from './eod-locations-info.vue';
import eodContactsInfo from './eod-contacts-info.vue';
import eodTags from './eod-tags.vue';

export default {
    name:'eod-company-info',
    components:{
        'eod-locations-info': eodLocationsInfo,
        'eod-contacts-info': eodContactsInfo,
        'eod-tags': eodTags,
    },
    props:{
        value: String,
        isPopup: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        loading: true,
        company: null,
        tab: 'general',
        sharepoint: null
    }),
    mounted(){
        this.$eod.getById('company', this.value, ['id name description tags{id name color} division{id name} department{id name} vatNumber users{id, firstName, lastName, username, email, assignedRealmRoles{id name}, attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}} locations{name description streetAddress postalCode locality region country notes department}'])
            .then(response => {
                if(response.data.data.company){
                    this.company = response.data.data.company;
                }
            }).then(() => {
                let sharepointURLSetting = this.$eod.getOrganizationSetting('share.sharepoint.companies.url');
                    if(sharepointURLSetting && sharepointURLSetting.value){
                        this.sharepoint = this.$helper.replaceTags(sharepointURLSetting.value, {
                            company: this.company
                        });
                    }
            }).finally(() => {
                this.loading = false;
            });
    }
}
</script>