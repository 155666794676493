<template>
  <div class="container-fluid" :style="fullHeight ? 'height:100%' : ''">
    <eod-events-table :height="fullHeight ? 'calc(100% - 60px)' : (height ? height : 300)" ref="table"
      :headers="visibleTableHeaders" :items="events" :expanded.sync="expanded" :options.sync="options"
      :server-items-length="totalEvents" :loading="loading" @item-expanded="itemExpanded" :items-per-page="itemsPerPage"
      @openSidebar="item => $emit('openSidebar', item)" @editItem="item => $emit('editItem', item)">

      <template v-slot:footer.prepend>
        <v-col cols="auto" class="py-1">
          <v-select v-model="activeTableHeaders" @change="val => $emit('activeHeadersChanged', val)" :items="tableHeaders"
            dense label="Toon kolommen" multiple single-line title="Toon kolommen..."
            :menu-props="{ top: true, offsetY: true }">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3" small>
                <span>{{ item.text }}</span>
              </v-chip>

              <span v-if="index === 3" class="grey--text text-caption">
                (+{{ activeTableHeaders.length - 3 }} andere)
              </span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="auto" class="py-1 ps-0">
          <v-btn icon title="Reset zichtbare kolommen" @click="resetVisibleTableHeaders">
            <v-icon size="18">mdi-backspace-outline</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto" class="ms-auto">
          <v-btn elevation="2" color="secondary" small title="New" rounded @click="addNewEventDialog = true">
            <v-icon size="20">mdi-bell-plus</v-icon>Nieuw event
          </v-btn>
        </v-col>
      </template>
    </eod-events-table>
    <eod-dialog width="800" v-model="addNewEventDialog" title="Event toevoegen" icon="mdi-plus" iconColor="primary"
      okText="Toevoegen" @okAction="addEventAction">
      <div class="pt-4">
      <eod-event ref="eventForm" v-model="newEvent"></eod-event>
    </div>
    </eod-dialog>
  </div>
</template>
<script>
import Event from './../models/event';
import User from './../models/user';
import eodDialog from './eod-dialog.vue';
import eodEvent from './eod-event.vue';
import eodEventsTable from './eod-events-table.vue';

export default {
  name: 'eod-events-drawer-table',
  components: {
    eodDialog,
    eodEvent,
    eodEventsTable
  },
  props: {
    filters: {
      type: Array,
      default: () => ([])
    },
    activeHeaders: {
      type: Array,
      default: null
    },
    timeFilter: {
      type: Object,
      default: null
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: null
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  mounted: async function () {
    const orgHeaders = await this.$eod.getOrganizationSetting('organisation.events.table.headers');
    if (this.activeHeaders) {
      this.activeTableHeaders = this.activeHeaders;
    } else if (orgHeaders && orgHeaders.value) {
      this.activeTableHeaders = JSON.parse(orgHeaders.value);
    } else {
      this.activeTableHeaders = this.tableHeaders.map(h => h.value);
    }
  },
  computed: {
    visibleTableHeaders() {
      let all = this.tableHeaders;

      const filtered = all.filter((header) => {
        return this.activeTableHeaders ? this.activeTableHeaders.includes(header.value) : [];
      });

      const defaults = [{
        text: '',
        align: 'start',
        sortable: false,
        value: 'id',
        groupable: false,
        width: 50
      },
      {
        text: '',
        value: 'data-table-expand',
        groupable: false
      },
      { text: 'Tijd', value: 'time', groupable: false }];

      return defaults.concat(filtered);
    }
  },

  data() {
    return {
      expanded: [],
      singleExpand: false,

      addNewEventDialog: false,
      newEvent: {
        time: (new Date()).toISOString(),
        duration: null, // 1 hour in seconds
        owner: this.$eod.getUser(),
        responsibleUser: this.$eod.getUser(),
        message: '',
        severity: 'info',
        status: 'open',
        userStatus: 'unassigned',
        type: 'new',
      },

      Event: Event,
      options: {
        sortBy: ['time'],
        sortDesc: [true]
      },
      totalEvents: 0,
      loading: true,
      events: [],
      errors: {},
      eventFields: ['id', 'groupCount', 'eventGroupUnique', 'time', 'message', 'severity', 'status', 'duration', 'value', 'type', 'facility', 'measurementType{id name}', 'owner{id}', 'responsibleUser{id}', 'project{id name}', 'company{id name}', 'product{id name}', 'storageObjects{id key name}', 'comments{createdAt userId content storageObjects{id key name}}'],
      tableHeaders: require('./../classes/settings').eventTableHeaders,
      activeTableHeaders: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.loadEvents();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.loadEvents();
      },
      deep: true,
    },
    timeFilter: {
      handler() {
        this.loadEvents();
      },
      deep: true,
    },
  },
  methods: {
    addEventAction() {
      const event = new Event(this.newEvent);
      event.time = (new Date()).toISOString();

      if (this.$refs.eventForm) {
        if (!this.$refs.eventForm.validate()) {
          return false;
        }
      }

      const data = event.getSaveData();
      this.$eod.save('Event', data, false, {
        create: {
          returnfields: this.fields
        }
      }).then(response => {
        if (response.data.data.createEvent) {
          this.loadEvents();
        }
      }).finally(() => {
        this.addNewEventDialog = false;
      });
    },
    itemExpanded({ item, value }) {
      if (value) {
        if (!item.children) {
          this.loadChildren(item).then(event => {
            this.setEventChildren(event.parent, event.children);
            this.expanded.push({});
            this.expanded.splice(this.expanded.length - 1, 1);
          });
        }
      }
    },
    setEventChildren(parent, children) {
      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];
        if (event.id == parent.id) {
          this.events[i].children = children;
          break;
        }
      }
    },
    loadChildren(event) {
      this.loading = true;
      return this.$eod.get('resolveEvents', this.eventFields, {
        orderBy: {
          column: 'time',
          type: 'desc'
        },
        where: [
          {
            column: 'eventGroupUnique',
            operator: '=',
            value: event.eventGroupUnique
          }
        ]
      }).then(response => {
        if (response.data.data.resolveEvents) {
          return {
            parent: event,
            children: response.data.data.resolveEvents.edges
          };
        }

        return null;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadEvents() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      const variables = {
        ...this.$helper.filtersToVariables(this.filters),
        orderBy: {
          column: sortBy[0] ? sortBy[0] : 'time',
          type: sortDesc[0] ? 'desc' : 'asc'
        },
        offset: (page ? page : 1) - 1,
        limit: (itemsPerPage ? itemsPerPage : this.itemsPerPage),
        groupBy: [{
          column: 'eventGroupUnique'
        }]
      };
      

      if (this.timeFilter) {
        if(!variables.where){
          variables.where = []
        }
        
        variables.where.push({
          column: 'time',
          operator: '>=',
          value: this.$moment(this.timeFilter.from).startOf('day').toISOString()
        });

        variables.where.push({
          column: 'time',
          operator: '<=',
          value: this.$moment(this.timeFilter.until).endOf('day').toISOString()
        });
      }

      this.expanded = [];

      this.loading = true;
      this.$eod.get('resolveEvents', this.eventFields, variables).then(async response => {
        if (response.data.data.resolveEvents) {
          let events = response.data.data.resolveEvents.edges;

          events = await this.resolveEventUsers(events, 'owner');
          events = await this.resolveEventUsers(events, 'responsibleUser');
          
          this.events = events;
          this.totalEvents = response.data.data.resolveEvents.totalCount;
        }
      }).finally(() => {
        this.loading = false;
      });
    },

    async resolveEventUsers(events, fieldname){
      const userIds = [];

          for (let i = 0; i < events.length; i++) {
            const event = events[i];
            if (event[fieldname] && !userIds.includes(event[fieldname].id)) {
              userIds.push(event[fieldname].id);
            }
          }

          if (userIds.length > 0) {
            const users = await this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username'], {
              whereIn: {
                column: 'id',
                array: userIds
              }
            })
              .then(response => {
                const arr = {};
                const users = response.data.data.resolveUsers.edges;
                for (let i = 0; i < users.length; i++) {
                  const user = users[i];
                  arr[user.id] = user;
                }
                return arr;
              });

            for (let i = 0; i < events.length; i++) {
              const event = events[i];
              if (event[fieldname] && users[event[fieldname].id]) {
                events[i][fieldname] = {
                  ...users[event[fieldname].id],
                  name: (new User(users[event[fieldname].id])).getFullName()
                };
              }
            }
          }

        return events;
    },

    resetVisibleTableHeaders() {
      this.activeTableHeaders = this.tableHeaders.map(h => h.value);
      this.$emit('activeHeadersChanged', this.activeTableHeaders);
    },
  },
}
</script>