<template>
    <div>
        <v-row v-if="!(type && type.id == 'RANGE' && value.length > 0) && !isSelectList && !readonly" class="mb-2">
            <v-col class="text-right">
                <v-btn color="primary" rounded @click="addItem"><v-icon>mdi-plus</v-icon> Toevoegen</v-btn>
            </v-col>
        </v-row>
        <v-card>
            <v-data-table v-if="type" :headers="headers" :items="value" :items-per-page="-1" :search="search"
                locale="nl-BE" item-key="id" :expanded.sync="expanded" :show-select="isSelectList" :key="updateTable">
                <template v-slot:item="{ item }">
                    <tr>
                        <td v-if="isSelectList">
                            <v-checkbox v-model="selected" :value="item.id" @change="selectionChanged" style="margin:0px;padding:0px" hide-details />
                        </td>
                        <td v-if="type.fields.includes('name')">{{ item.name }}</td>
                        <td v-if="type.fields.includes('user')">
                            {{
                            getCachedUser(item.name)?getCachedUser(item.name).getFullName():
                            ''}}
                        </td>
                        <td v-if="type.fields.includes('object')">
                            {{
                            getCachedObject(item.name)?getCachedObject(item.name).name:
                            ''}}
                        </td>
                        <td v-if="type.fields.includes('threshold')">{{ item.threshold }}</td>
                        <td v-if="type.fields.includes('low')">{{ item.low }}</td>
                        <td v-if="type.fields.includes('high')">{{ item.high }}</td>
                        <td v-if="type.fields.includes('stepSize')">{{ item.stepSize }}</td>
                        <td v-if="type.fields.includes('color')">
                            <v-avatar v-if="item.color" size="15"
                                :color="item.color"></v-avatar>
                        </td>
                        <td v-for="field in activeFields" :key="field.index">
                            {{ item.fields[field.index].name }}
                        </td>
                        <td v-if="!isSelectList">
                            <v-switch class="mt-0" v-model="item.isActive" color="success"
                                :true-value="true" :false-value="false" hide-details inset
                                :disabled="readonly"></v-switch>
                        </td>
                        <td v-if="!isSelectList">
                            <eod-actions-column :showDelete="item.id ? false : true" :item="item"
                                :deleteAction="deleteItem" :editAction="editItem"></eod-actions-column>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-card flat color="#F8F8F8">
                            <v-card-text>
                                <v-row>
                                    <v-col v-if="type.fields.includes('name')">
                                        <eod-text-field :disabled="readonly" label="Naam"
                                            v-model="item.name"></eod-text-field>
                                        <eod-translations v-model="item.name"></eod-translations>
                                    </v-col>
                                    <v-col v-if="type.fields.includes('threshold')">
                                        <eod-text-field :disabled="readonly" v-model.number="item.threshold"
                                            label="Waarde"></eod-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col v-if="type.fields.includes('user')">
                                        <eod-autocomplete :disabled="readonly" :search-input.sync="userSearch" :loading="userIsUpdating"
                                            :items="users" no-filter hide-details single-line item-value="id"
                                            auto-select-first v-model="item.name" label="Gebruiker">
                                            <template v-slot:selection="{ item }">
                                                {{ item.getFullName() }}
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar color="indigo"
                                                    class="headline font-weight-light white--text">
                                                    {{ item.getInitials() }}
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.getFullName()"></v-list-item-title>
                                                    <v-list-item-subtitle>{{
                                                        $t(item.getRole().name)
                                                    }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </eod-autocomplete>
                                    </v-col>
                                    <v-col v-if="type.fields.includes('object')">
                                        <eod-autocomplete :disabled="readonly" :search-input.sync="objectSearch" :loading="objectIsUpdating"
                                            :items="objectSearchResults" no-filter hide-details single-line
                                            item-value="id" item-text="name" auto-select-first
                                            v-model="item.name" label="Item">
                                        </eod-autocomplete>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col v-if="type.fields.includes('low')">
                                        <eod-text-field :disabled="readonly" type="number" label="Min"
                                            v-model.number="item.low"></eod-text-field>
                                    </v-col>
                                    <v-col v-if="type.fields.includes('high')">
                                        <eod-text-field :disabled="readonly" type="number" label="Max"
                                            v-model.number="item.high"></eod-text-field>
                                    </v-col>
                                    <v-col v-if="type.fields.includes('stepSize')">
                                        <eod-text-field :disabled="readonly" type="number" label="Stap"
                                            v-model.number="item.stepSize"></eod-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" v-if="type.fields.includes('color')">
                                        <eod-color-picker :disabled="readonly" label="Kleur" v-model="item.color" dot-size="15"
                                            hide-mode-switch mode="hex"></eod-color-picker>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm="12" md="6" v-for="field in activeFields" :key="field.index">
                                        <eod-text-field :disabled="readonly" :label="field.name"
                                            v-model="item.fields[field.index].name"></eod-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { User } from '@/eodvuecomponents';
import eodColumnActions from './columns/eod-column-actions';
import eodTranslations from './eod-translations';
import eodColorPicker from './eod-color-picker.vue';

export default {
    name: 'eod-list-items',
    components: {
        'eod-actions-column': eodColumnActions,
        'eod-translations': eodTranslations,
        'eod-color-picker': eodColorPicker,
    },
    props: {
        value: Array,
        fields: Array,
        type: Object,
        selected: Array,
        isSelectList: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: '',
            expanded: [],
            userSearch: '',
            userSearchResults: [],
            roles: [],
            userIsUpdating: false,
            timeout: null,
            userCache: [],

            itemsWithIndex: [],

            objectSearch: '',
            objectSearchResults: [],
            objectIsUpdating: false,
            objectCache: [],

            updateTable: 0
        }
    },
    created() {
        let settings = require('./../classes/settings');
        this.roles = settings.roles.collaborators.concat(settings.roles.contacts);
    },
    watch: {
        value(val) {
            let items = [];
            if (val) {
                for (let i = 0; i < val.length; i++) {
                    let item = Object.assign({}, val[i]);
                    if (!item.isDeleted) {
                        if (!item.color) {
                            item.color = '';
                        }
                        item.index = i;
                        items.push(item);
                    }
                }
            }

            this.itemsWithIndex = items;
        },
        type(val) {
            this.getNamedItems(val);
        },
        userSearch(val) {

            // Items have already been requested
            if (this.timeout) {
                clearInterval(this.timeout);
            }

            this.timeout = setTimeout(() => {
                if (this.userIsUpdating) return;

                if (!val || val == '' || val.length < 2) {
                    return;
                };

                this.userIsUpdating = true;

                let variables = {
                    search: val,
                    firstName: val,
                    lastName: val,
                }

                // Filter users
                this.$eod.get('users', ['id', 'firstName', 'lastName', 'username', 'assignedRealmRoles{name}'], variables)
                    .then(result => {
                        this.userSearchResults = [];
                        let count = result.data.data.users.edges.length;
                        for (let i = 0; i < count; i++) {
                            this.userSearchResults.push(new User(result.data.data.users.edges[i]));
                        }
                        this.cacheUsers(this.userSearchResults);
                        this.userIsUpdating = false;
                        //this.$forceUpdate();
                    }).finally(() => {
                        this.timeout = null;
                    });

            }, 200);
        },
        objectSearch(val) {

            // Items have already been requested
            if (this.timeout) {
                clearInterval(this.timeout);
            }

            this.timeout = setTimeout(() => {
                if (this.objectIsUpdating) return;

                if (!val || val == '' || val.length < 2) {
                    return;
                };

                this.objectIsUpdating = true;

                let variables = {
                    where: [
                        {
                            column: 'name',
                            operator: "ILIKE",
                            value: "%" + val + "%",
                            chain: "or"
                        },
                        {
                            column: 'description',
                            operator: "ILIKE",
                            value: "%" + val + "%",
                            chain: "or"
                        }
                    ]
                }

                // Filter objects
                this.$eod.get(this.type.endpoint, ['id', 'name'], variables)
                    .then(result => {
                        this.objectSearchResults = [];
                        let count = result.data.data[this.type.endpoint].edges.length;
                        for (let i = 0; i < count; i++) {
                            this.objectSearchResults.push(new User(result.data.data[this.type.endpoint].edges[i]));
                        }
                        this.cacheObjects(this.objectSearchResults);
                        this.objectIsUpdating = false;
                    }).finally(() => {
                        this.timeout = null;
                    });

            }, 200);
        },
    },
    computed: {
        selectedItems() {
            const items = [];
            if (this.selected) {
                for (let i = 0; i < this.selected.length; i++) {
                    const selectedItem = this.selected[i];
                    for (let j = 0; j < this.itemsWithIndex.length; j++) {
                        const item = this.itemsWithIndex[j];
                        if (selectedItem.id == item.id) {
                            items.push(item);
                            break;
                        }
                    }
                }

                this.getNamedItems(this.type);
            }

            return items;
        },
        users() {
            let arr_items = this.userSearchResults;

            for (let i = 0; i < arr_items.length; i++) {
                if (arr_items[i].getRole() == null) {
                    arr_items.splice(i, 1);
                } else if (this.roles && !this.roles.includes(arr_items[i].getRole().name)) {
                    arr_items.splice(i, 1);
                }
            }

            return arr_items;
        },
        activeFields() {
            let fields = [];
            if (this.fields) {
                for (let i = 0; i < this.fields.length; i++) {
                    const field = Object.assign({}, this.fields[i]);
                    field.index = i;
                    if (field.isActive) {
                        fields.push(field);
                    }
                }
            }

            return fields;
        },
        headers() {
            let headers = [];

            if (this.type.fields.includes('name') || this.type.fields.includes('user') || this.type.fields.includes('object')) {
                headers.push({
                    text: 'Naam'
                });
            }

            if (this.type.fields.includes('threshold')) {
                headers.push({
                    text: 'Waarde'
                });
            }

            if (this.type.fields.includes('low')) {
                headers.push({
                    text: 'Min',
                });
            }

            if (this.type.fields.includes('high')) {
                headers.push({
                    text: 'Max',
                });
            }

            if (this.type.fields.includes('stepSize')) {
                headers.push({
                    text: 'Stap',
                });
            }

            if (this.type.fields.includes('color')) {
                headers.push({
                    text: 'Kleur',
                });
            }

            if (this.fields) {
                for (let i = 0; i < this.activeFields.length; i++) {
                    const field = this.activeFields[i];
                    headers.push({
                        text: field.name,
                    });
                }
            }

            if (!this.isSelectList) {
                headers.push({
                    width: 104,
                    sortable: false
                });

                headers.push({
                    width: 104,
                    sortable: false
                });
            }

            return headers;
        },
    },
    methods: {
        selectionChanged(selected){
            this.$emit('selectionChanged', selected);
        },
        getNamedItems(val) {
            if (val.type == 'user') {
                let ids = [];
                for (let i = 0; i < this.value.length; i++) {
                    if (this.value[i].name) {
                        ids.push(this.value[i].name);
                    }
                }

                this.$eod.get('users', ['id', 'firstName', 'lastName', 'username', 'assignedRealmRoles{name}'], {
                    whereIn: {
                        column: 'id',
                        array: ids
                    }
                })
                    .then(result => {
                        let users = [];
                        let count = result.data.data.users.edges.length;
                        for (let i = 0; i < count; i++) {
                            users.push(new User(result.data.data.users.edges[i]));
                        }
                        this.cacheUsers(users);
                    });
            } else if (val.type == 'object') {
                let ids = [];
                for (let i = 0; i < this.value.length; i++) {
                    if (this.value[i].name) {
                        ids.push(this.value[i].name);
                    }
                }

                this.$eod.get(val.endpoint, ['id', 'name'], {
                    whereIn: {
                        column: 'id',
                        array: ids
                    }
                })
                    .then(result => {
                        let objects = [];
                        let count = result.data.data[val.endpoint].edges.length;
                        for (let i = 0; i < count; i++) {
                            objects.push(new User(result.data.data[val.endpoint].edges[i]));
                        }
                        this.cacheObjects(objects);
                    });
            }
        },
        getCachedUser(userid) {
            for (let i = 0; i < this.userCache.length; i++) {
                const user = this.userCache[i];
                if (user.id == userid) {
                    return user;
                }
            }
        },
        cacheUsers(items) {
            for (let i = 0; i < items.length; i++) {
                let found = false;
                const user = items[i];
                for (let j = 0; j < this.userCache.length; j++) {
                    const cacheUser = this.userCache[j];
                    if (user.id == cacheUser.id) {
                        found = true;
                    }
                }

                if (!found) {
                    this.userCache.push(user);
                }
            }
        },
        getCachedObject(objectid) {
            for (let i = 0; i < this.objectCache.length; i++) {
                const object = this.objectCache[i];
                if (object.id == objectid) {
                    return object;
                }
            }
        },
        cacheObjects(items) {
            for (let i = 0; i < items.length; i++) {
                let found = false;
                const user = items[i];
                for (let j = 0; j < this.objectCache.length; j++) {
                    const cache = this.objectCache[j];
                    if (user.id == cache.id) {
                        found = true;
                    }
                }

                if (!found) {
                    this.objectCache.push(user);
                }
            }
        },
        deleteItem(item, callback) {
            if (item.id) {
                this.value[item.index].isDeleted = true;
            } else {
                this.value.splice(item.index, 1);
            }
            this.$emit('input', this.value);

            callback();
        },
        editItem(item) {
            // If index, get item
            if (!isNaN(item)) {
                item = this.itemsWithIndex[item];
            }

            if (this.expanded.includes(item)) {
                this.expanded = [];
            } else {
                this.expanded = [item];
            }
        },
        addItem() {
            if (!this.value) {
                this.value = [];
            }

            let item = {
                name: '',
                fields: [],
                isActive: true,
                threshold: this.value.length + 1
            };

            for (let i = 0; i < this.fields.length; i++) {
                item.fields[i] = {
                    name: '',
                    isActive: true
                }
            }

            this.value.push(item);

            this.editItem(this.value.length - 1);

            this.$emit('input', this.value);
        },
    }
}
</script>
