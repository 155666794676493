<template>
    <div id="response_form">
        <v-form ref="form">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div v-if="step && !loading">
            <v-card class="mb-4" v-if="template.content.variants && template.content.variants.length > 0">
                <v-card-text>
                    <v-autocomplete :disabled="readonlyVariant" :items="template.content.variants" dense outlined
                        label="Variant" hide-details v-model="template.content.variant"
                        @change="updateTemplate"></v-autocomplete>
                </v-card-text>
            </v-card>
            <v-card class="mb-4" v-if="variantsCheck && variantVariables && variantVariables.length > 0">
                <v-card-title>Toestellen</v-card-title>
                <v-list dense>
                    <v-list-item v-for="variable in variantVariables" :key="variable.value">
                        <v-list-item-content>
                            <v-list-item-title>{{ variable.text }}</v-list-item-title>
                            <v-list-item-subtitle v-if="variable.product">{{
                                variable.product.name
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action style="flex-grow:4;" v-if="!variable.product || variable.edit">
                            <eod-objects-treeview-input label="Toestel" :selectable="['DEVICE']" v-model="template.content.variables[variable.index].product" @input="updateTemplate" return-object></eod-objects-treeview-input>
                        </v-list-item-action>
                        <v-list-item-action v-if="variable.product && !readonly">
                            <v-btn v-if="!variable.edit"
                                @click="template.content.variables[variable.index].edit = true; $forceUpdate()"
                                icon><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn v-else icon
                                @click="template.content.variables[variable.index].edit = false; $forceUpdate()"><v-icon>mdi-check</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
            <template v-if="variantsCheck && variablesCheck && !loading">
                <v-subheader>{{ step.name }}</v-subheader>
                <div class="mb-4" v-if="step.description && step.description != ''" v-html="step.description"></div>
                <div class="stepper-content">
                    <v-lazy class="mb-4" v-for="(cell, cellIndex) in variantCells" :options="{
                        threshold: .5
                    }" min-height="100" transition="fade-transition" :key="cellIndex">
                        <div class="text-h6 mb-2" v-if="cell.typeId == 'title'" v-html="getTranslation(cell.name)">
                        </div>
                        <div class="text-subtitle-2 mb-2" v-if="cell.typeId == 'subtitle'"
                            v-html="getTranslation(cell.name)"></div>
                        <div class="text-body-2 mb-2" v-if="cell.typeId == 'text' && !cell.settings.isCollapsable"
                            v-html="getTranslation(cell.name)"></div>
                        <v-expansion-panels class="mb-2" v-if="cell.typeId == 'text' && cell.settings.isCollapsable">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    {{ getTranslation(cell.settings.collapsableTitle) }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-html="getTranslation(cell.name)"></div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <eod-card v-if="cell.typeId == 'measurementType'" style="overflow:hidden;">
                            <template v-slot:header>
                                <div class="primary-10">
                                <v-card-title class="text-body-1 py-2">
                                    
                <div class="d-flex flex-column">
                    
                    <div v-if="cell.settings.showTitle && getMeasurementType(cell.settings.measurementTypeId)">
                        <v-icon left small v-if="getMeasurementType(cell.settings.measurementTypeId).icon">mdi-{{ getMeasurementType(cell.settings.measurementTypeId).icon }}</v-icon>
                        {{getMeasurementType(cell.settings.measurementTypeId).name}}<em v-if="getMeasurementType(cell.settings.measurementTypeId).formConfig.required">*</em></div>
                    <div v-if="getProduct(cell)" class="text-caption"><v-icon small left>mdi-package-variant</v-icon>{{ getProduct(cell).name }}</div>
                                </div>
                            <v-spacer></v-spacer>
                            <v-btn color="error" icon @click="deleteCell(cell)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                            <eod-delete-dialog @delete="deleteCellAction" v-model="isDeleteCellDialogVisible" :item="getMeasurementType(cell.settings.measurementTypeId)"></eod-delete-dialog>
                            </v-card-title>
                            <v-card-subtitle
                                v-if="cell.settings.showDescription && getMeasurementType(cell.settings.measurementTypeId) && getMeasurementType(cell.settings.measurementTypeId).description">{{
                                    getMeasurementType(cell.settings.measurementTypeId).description
                                }}<em
                                    v-if="getMeasurementType(cell.settings.measurementTypeId).formConfig.required">*</em></v-card-subtitle>
                            </div>
                                </template>
                            
                                    <eod-measurement
                                    v-if="value && value.job && value.job.project && value.job.jobType && getMeasurementIndexByCell(cell) != null && getMeasurementType(cell.settings.measurementTypeId)"
                                    ref="measurement" create-task @onCreateTask="value => $emit('onCreateTask', value)"
                                    :cell="cell" :product="getProduct(cell)"
                                    :measurementType="getMeasurementType(cell.settings.measurementTypeId)"
                                    :value="value.measurements[getMeasurementIndexByCell(cell)]"
                                    :projectId="value.job.project.id" :jobTypeId="value.job.jobType.id"
                                    @input="updateCellMeasurement" :readonly="readonly"></eod-measurement>
                                    <v-alert class="mb-0" color="info" text v-else-if="getMeasurementIndexByCell(cell) == null">
                                        Kon instellingen van de meetwaarde niet ophalen.
                                    </v-alert>
                        </eod-card>
                    </v-lazy>
                    <div class="d-flex">
                        <v-btn class="mb-4" large text v-if="isPreviousStep()" @click="goToPreviousStep">{{
                            getTranslation('Vorige')
                        }}</v-btn>
                        <v-spacer />
                        <v-btn class="mb-4" large v-if="isNextStep()" color="secondary" @click="goToNextStep">
                            {{ getTranslation('Volgende') }}
                        </v-btn>
                        <v-btn class="mb-4" large v-if="!isNextStep() && sendButton && !readonly" color="secondary"
                            @click="$emit('submit')">
                            {{ getTranslation('Verzenden') }}
                        </v-btn>
                    </div>
                </div>
            </template>
        </div>
        </v-form>
        <v-btn v-if="mobile" class="btn-back-to-top" :class="scrollTop ? 'show' : ''" @click="backToTop" fab small color="primary"
            dark><v-icon>mdi-chevron-up</v-icon></v-btn>
    </div>
</template>
<style lang="scss" scoped>
.btn-back-to-top {
    position: fixed;
    right: -60px;
    bottom: 65px;
    z-index: 2;

    opacity: 0;
    transition: .5s all;

    &.show {
        right: 5px;
        opacity: 1;
    }
}
</style>
<script>
import eodMeasurement from './eod-measurement.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodDeleteDialog from './eod-delete-dialog.vue';

export default {
    name: 'eod-task-form',
    components: {
        eodMeasurement,
        eodObjectsTreeviewInput,
        eodDeleteDialog
    },
    props: {
        value: Object | Array,
        template: {
            type: Object,
            default: null
        },
        lang: {
            type: String,
            default: 'nl'
        },
        sendButton: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        mobile: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        stepIndex: 0,
        step: null,
        loading: false,
        translationsLoading: false,
        translations: {},
        scrollTop: false,
        scrollInterval: null,
        measurementTypes: [],
        measurementTypesResolved: {},
        products: [],
        readonlyVariant: false,
        visibleIndex: 0,
        dashboard_settings: require('@/eodvuecomponents/src/classes/dashboard.js'),
        dashboard: null,
        activeCell: null,
        isDeleteCellDialogVisible: false
    }),
    computed: {
        variantCells() {
            if (!this.step) {
                return [];
            }

            let cells = [];
            for (let i = 0; i < this.step.cells.length; i++) {
                const cell = this.step.cells[i];
                if (cell.typeId == 'measurementType') {
                    if ((!cell.settings.variants || cell.settings.variants.length == 0) || cell.settings.variants.includes(this.template.content.variant)) {
                        cells.push(cell);
                    }
                }
            }

            return cells;
        },
        variantsCheck() {
            return (!this.template.content.variants || this.template.content.variants.length <= 0 || (this.template.content.variants && this.template.content.variant));
        },
        variablesCheck() {
            let check = true;
            if (this.variantVariables) {
                for (let i = 0; i < this.variantVariables.length; i++) {
                    const variable = this.variantVariables[i];
                    if (!variable.product) {
                        check = false;
                    }
                }
            }
            return check;
        },
        variantVariables() {
            let variables = [];
            if (!this.template.content.variables) {
                return null;
            }

            let variableIds = [];
            let cells = this.variantCells;
            for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];
                if (cell.typeId == 'measurementType' && cell.settings.product && !variableIds.includes(cell.settings.product.variable)) {
                    variableIds.push(cell.settings.product.variable);
                }
            }

            for (let i = 0; i < this.template.content.variables.length; i++) {
                let variable = this.template.content.variables[i];
                if (variableIds.includes(variable.value)) {
                    variable.index = i;
                    variables.push(variable);
                }
            }

            return variables;
        }
    },
    mounted() {
        if (this.template) {
            this.loading = true;

            this.loadMeasurementTypesOfTemplate()
                .then(() => {
                    if (this.variantsCheck && this.variablesCheck) {
                        return this.loadProductsOfTemplate();
                    }

                    return true;
                }).then(() => {
                    if (this.template.content) {
                        let steps = this.getSteps();
                        if (steps && steps[this.stepIndex]) {
                            this.step = steps[this.stepIndex];
                        }

                        if (this.template.content.variant) {
                            this.readonlyVariant = true;
                        }
                    }
                }).then(() => {
                    return this.loadTranslations();
                }).then(() => {
                    this.enableBackToTop();
                }).finally(() => {
                    this.$emit('loaded');
                    this.loading = false;
                });
        }
    },
    beforeDestroy() {
        clearInterval(this.scrollInterval);
    },
    watch: {
        stepIndex(val) {
            this.loadTranslations();
        },
        lang(val) {
            this.refresh();
        }
    },
    methods: {
        deleteCell(cell){
            this.activeCell = cell;
            // Show delete dialog

            this.isDeleteCellDialogVisible = true;
        },
        deleteCellAction(){
            this.$emit('deleteCell', this.activeCell);
            this.isDeleteCellDialogVisible = false;
        },
        async updateTemplate() {
            this.$emit('updateTemplate', this.template);

            if (this.variantsCheck && this.variablesCheck) {
                await this.loadProductsOfTemplate();
            }

            this.$forceUpdate();
        },
        getProduct(cell) {
            let productId = this.getProductIdOfCell(cell);
            if (productId) {
                for (let i = 0; i < this.products.length; i++) {
                    const product = this.products[i];
                    if (product.id == productId) {
                        return product;
                    }
                }
            }
            return null;
        },
        updateCellMeasurement(measurement) {
            let response = this.value;

            response.measurements[this.getMeasurementIndex(measurement)].value = measurement.value;

            this.$emit('input', response);
        },
        getMeasurementIndex(searchMeasurement) {
            for (let i = 0; i < this.value.measurements.length; i++) {
                const measurement = this.value.measurements[i];
                if (measurement.measurementType.id != searchMeasurement.measurementType.id) {
                    continue;
                }

                if (searchMeasurement.product && searchMeasurement.product.id != measurement.product.id) {
                    continue;
                }

                if (!searchMeasurement.product && measurement.product != null) {
                    continue;
                }

                return i;
            }
        },
        getMeasurementIndexByCell(cell) {

            if (!this.value || !this.value.measurements || !cell.settings.measurementTypeId) {
                return null;
            }

            let response = this.value;

            const measurementType = this.getMeasurementType(cell.settings.measurementTypeId);
            const product = this.getProduct(cell);

            if(!measurementType){
                return null;
            }

            let latestMeasurementIndex = null; //Fix for app sending multiple measurements for same MeasurementType/Product
            for (let i = 0; i < this.value.measurements.length; i++) {
                const measurement = this.value.measurements[i];
                if (measurement.measurementType.id != measurementType.id) {
                    continue;
                }

                if (product && measurement.product && product.id != measurement.product.id) {
                    continue;
                }

                if (!product && measurement.product != null) {
                    continue;
                }

                if (latestMeasurementIndex == null || (latestMeasurementIndex != null && this.$moment(this.value.measurements[latestMeasurementIndex].time).isBefore(this.$moment(measurement.time)))) {
                    latestMeasurementIndex = i;
                }
            }

            if (latestMeasurementIndex != null) {
                return latestMeasurementIndex;
            }

            if (this.variantsCheck && this.variablesCheck && measurementType) {

                let measurement = {
                    organization: { id: this.$eod.getRealm() },
                    owner: { id: this.$eod.getUser().id },
                    measurementType: { id: measurementType.id },
                    value: null,
                    isCreate: true
                };

                if (product) {
                    measurement.product = { id: product.id };
                }

                response.measurements.push(measurement);

                this.$emit('input', response);
            }

            return response.measurements.length - 1;
        },
        getProductIdOfCell(cell) {
            if (cell.typeId == 'measurementType' && cell.settings.product) {
                if (cell.settings.product.type == 'dynamic') {
                    for (let variableIndex = 0; variableIndex < this.template.content.variables.length; variableIndex++) {
                        const variable = this.template.content.variables[variableIndex];
                        if (variable.value == cell.settings.product.variable && variable.product) {
                            return variable.product.id;
                        }
                    }
                } else {
                    return cell.settings.product.id;
                }
            }

            return null;
        },
        async loadProductsOfTemplate() {
            let productIds = [];

            if (!this.template.content) {
                return false;
            }

            for (let pageIndex = 0; pageIndex < this.template.content.pages.length; pageIndex++) {
                const page = this.template.content.pages[pageIndex];
                for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                    const cell = page.cells[cellIndex];
                    const productId = this.getProductIdOfCell(cell);

                    if (productId) {
                        productIds.push(this.getProductIdOfCell(cell));
                    }
                }
            }

            if(productIds.length > 0){
                await this.$eod.get('products', ['id name'], {
                    whereIn: {
                        column: 'id',
                        array: productIds
                    }
                }).then(response => {
                    if (response.data.data.products) {
                        this.products = response.data.data.products.edges;
                    }
                });
            }
            
        },
        loadMeasurementTypesOfTemplate() {
            let measurementTypeIds = [];

            if (!this.template.content) {
                return false;
            }

            for (let pageIndex = 0; pageIndex < this.template.content.pages.length; pageIndex++) {
                const page = this.template.content.pages[pageIndex];
                for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                    const cell = page.cells[cellIndex];
                    if (cell.typeId == 'measurementType' && cell.settings.measurementTypeId) {
                        measurementTypeIds.push(cell.settings.measurementTypeId);
                    }
                }
            }

            return this.$eod.get('measurementTypes', ['id name icon description unit{id name} typeConfig valueConfigList{id name itemType description listItems{id name isActive op low high threshold stepSize} isActive} valueConfigListItems{id name isActive op low high threshold stepSize} formConfig{category type subCategory enabled required photo photoRequired comment commentRequired default defaultValue values}'], {
                whereIn: {
                    column: 'id',
                    array: measurementTypeIds
                }
            }).then(response => {
                if (response.data.data.measurementTypes) {
                    this.measurementTypes = response.data.data.measurementTypes.edges;
                }
            });

        },
        validate() {
            if(this.$refs.form){
                return this.$refs.form.validate();
            }

            return valid;
        },
        getSteps() {
            let steps = Object.assign([], this.template.content.pages);

            // Check rules
            let rules = this.template.content.rules;

            if (rules) {
                for (let i = 0; i < rules.length; i++) {
                    const rule = rules[i];
                    if (rule.action == 'hideStep') {
                        switch (rule.operator) {
                            case '=':
                                if (typeof rule.values == 'number' && rule.values == this.getMeasurementType(rule.measurementTypeId).value) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                } else if (typeof rule.values == 'object' && rule.values.includes(this.getMeasurementType(rule.measurementTypeId).value)) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                }
                                break;
                            case '<>':
                                if (typeof rule.values == 'number' && rule.values != this.getMeasurementType(rule.measurementTypeId).value) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                } else if (typeof rule.values == 'object' && !rule.values.includes(this.getMeasurementType(rule.measurementTypeId).value)) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                }
                                break;
                        }
                    }
                }
            }

            return steps;
        },
        getStepIndexById(steps, id) {
            for (let i = 0; i < steps.length; i++) {
                const step = steps[i];
                if (step.id == id) {
                    return i;
                }
            }

            return null;
        },
        refresh() {
            for (let i = 0; i < this.$refs.measurement.length; i++) {
                const measurement = this.$refs.measurement[i];
                measurement.refresh();
            }
            this.$forceUpdate();
        },
        isNextStep() {
            let steps = this.getSteps();

            if (steps) {
                if (steps[this.stepIndex + 1]) {
                    return true;
                }
            }

            return false;
        },
        isPreviousStep() {
            let steps = this.getSteps();

            if (steps) {
                if (steps[this.stepIndex - 1]) {
                    return true;
                }
            }

            return false;
        },
        getMeasurementType(measurementTypeId) {
            if (!this.measurementTypes) {
                return null;
            }

            if(this.measurementTypesResolved[measurementTypeId]){
                return this.measurementTypesResolved[measurementTypeId];
            }

            let measurementType = this.measurementTypes[this.getMeasurementTypeIndex(measurementTypeId)];
            if (measurementType) {
                measurementType = this.translateListItems(measurementType);
            }

            this.measurementTypesResolved[measurementTypeId] = measurementType;

            return measurementType;
        },
        getMeasurementTypeIndex(measurementTypeId) {
            for (let i = 0; i < this.measurementTypes.length; i++) {
                const element = this.measurementTypes[i];
                if (element.id == measurementTypeId) {
                    return i;
                }
            }

            return null;
        },
        goToNextStep() {
            this.loading = true;
            this.stepIndex++;

            let steps = this.getSteps();
            if (steps && steps[this.stepIndex]) {
                this.step = steps[this.stepIndex];
            }

            this.$nextTick(() => {
                this.$forceUpdate();
                this.backToTop();
                this.loading = false;
            });
        },
        goToPreviousStep() {
            this.loading = true;
            this.stepIndex--;

            let steps = this.getSteps();
            if (steps && steps[this.stepIndex]) {
                this.step = steps[this.stepIndex];
            }

            this.$nextTick(() => {
                this.$forceUpdate();
                this.backToTop();
                this.loading = false;
            });
        },
        translateListItems(measurementType) {
            if (measurementType.valueConfigListItems && measurementType.valueConfigListItems.length > 0) {
                for (let i = 0; i < measurementType.valueConfigListItems.length; i++) {
                    const listItem = measurementType.valueConfigListItems[i];
                    if (!listItem.originalName) {
                        measurementType.valueConfigListItems[i].originalName = listItem.name;
                    }

                    let name = measurementType.valueConfigListItems[i].originalName;
                    measurementType.valueConfigListItems[i].name = this.getTranslation(name);
                }
            } else if (measurementType.valueConfigList && measurementType.valueConfigList.listItems && measurementType.valueConfigList.listItems.length > 0) {
                for (let i = 0; i < measurementType.valueConfigList.listItems.length; i++) {
                    const listItem = measurementType.valueConfigList.listItems[i];
                    if (!listItem.originalName) {
                        measurementType.valueConfigList.listItems[i].originalName = listItem.name;
                    }

                    let name = measurementType.valueConfigList.listItems[i].originalName;
                    measurementType.valueConfigList.listItems[i].name = this.getTranslation(name);
                }
            }

            return measurementType;
        },
        loadTranslations() {
            if (!this.translations[this.stepIndex] && this.step) {
                this.translationsLoading = true;
                let keys = ['Vorige', 'Volgende', 'Verzenden'];
                for (let i = 0; i < this.step.cells.length; i++) {
                    const cell = this.step.cells[i];

                    if (cell.typeId == "measurementType") {
                        if (cell.settings) {
                            let measurementType = this.getMeasurementType(cell.settings.measurementTypeId);
                            if (measurementType && cell.settings.showDescription && measurementType.description && !keys.includes(measurementType.description)) {
                                keys.push(measurementType.description);
                            }
                            if (measurementType && cell.settings.showTitle && measurementType.name && !keys.includes(measurementType.name)) {
                                keys.push(measurementType.name);
                            }

                            if (measurementType && measurementType.valueConfigListItems && measurementType.valueConfigListItems.length > 0) {
                                for (let i = 0; i < measurementType.valueConfigListItems.length; i++) {
                                    const listItem = measurementType.valueConfigListItems[i];
                                    if (!keys.includes(listItem.name)) {
                                        keys.push(listItem.name);
                                    }

                                }
                            } else if (measurementType && measurementType.valueConfigList && measurementType.valueConfigList.listItems && measurementType.valueConfigList.listItems.length > 0) {
                                for (let i = 0; i < measurementType.valueConfigList.listItems.length; i++) {
                                    const listItem = measurementType.valueConfigList.listItems[i];
                                    if (!keys.includes(listItem.name)) {
                                        keys.push(listItem.name);
                                    }
                                }
                            }
                        }
                    } else if (['text', 'title', 'subtitle'].includes(cell.typeId)) {
                        if (cell.name && !keys.includes(cell.name)) {
                            keys.push(cell.name);
                        }

                        if (cell.typeId == 'text' && cell.settings.collapsableTitle && !keys.includes(cell.settings.collapsableTitle)) {
                            keys.push(cell.settings.collapsableTitle);
                        }
                    }
                }

                if (keys.length > 0) {
                    return this.$eod.get('resolveListItems', ['id', 'name', 'fields{name translations{locale value}}'], {
                        whereIn: {
                            column: 'name',
                            array: keys
                        }
                    })
                        .then(response => {
                            this.translations[this.stepIndex] = response.data.data.resolveListItems.edges;
                            //this.translateListItems();
                            this.$forceUpdate();
                        })
                        .finally(() => {
                            this.translationsLoading = false;
                        });
                } else {
                    this.translationsLoading = false;
                }
            }

            return false;
        },
        getTranslation(key) {
            if (this.translations[this.stepIndex]) {
                for (let i = 0; i < this.translations[this.stepIndex].length; i++) {
                    const listItem = this.translations[this.stepIndex][i];
                    if (listItem.name == key && listItem.fields) {
                        for (let j = 0; j < listItem.fields.length; j++) {
                            const field = listItem.fields[j];
                            if (field.name == 'value' && field.translations) {
                                for (let k = 0; k < field.translations.length; k++) {
                                    const translation = field.translations[k];
                                    if (translation.locale == this.lang && translation.value) {
                                        return translation.value;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            return key;
        },
        enableBackToTop() {
            if(this.mobile){
                let self = this;
                let timeout = null;
                this.scrollInterval = setInterval(() => {
                    const form = document.getElementById('response_form');
                    if (form) {
                        if (form.scrollTop > 100 || document.body.scrollTop) {
                            clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                self.scrollTop = false;
                            }, 2000);

                            if (!self.scrollTop) {
                                self.scrollTop = true;
                            }
                        } else {
                            if (self.scrollTop) {
                                self.scrollTop = false;
                            }
                        }
                    }
                }, 500);
            }
        },
        backToTop() {
            const form = document.getElementById('response_form');
            if (form) {
                form.scrollTop = 0; // For Safari
            }

            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            document.body.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
}
</script>