<template>
<div>
    <CoolLightBox
        :key="update"
      :items="getStorageObjects()" 
      :index="index"
      @close="index = null" :gallery="false">
    </CoolLightBox>

    <div class="d-flex">
        <div class="flex-grow-1">
            <eod-search v-if="config.search && searchable" :loading="loading" @update="getDataFromApi" v-model="filters" :config="config.search"></eod-search>
        </div>
        <v-menu v-if="uploadBtn" offset-y close-on-click close-on-content-click>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mb-2 ml-2" color="secondary" rounded>
                    <v-icon left>mdi-plus-box-multiple</v-icon>
                    Toevoegen
                    <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-if="uploadComputer" @click="addItem">
                    <v-list-item-icon>
                        <v-icon>mdi-laptop</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Computer</v-list-item-title>
                        <input ref="newFile" id="newFile" @change="uploadFiles" type="file" class="d-none" multiple />
                    </v-list-item-content>
                </v-list-item>

                <v-dialog v-if="uploadLibrary" v-model="uploadLibraryDialog" scrollable>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon>
                                <v-icon>mdi-image-multiple-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Opslag</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card flat v-if="uploadLibraryDialog">
                        <v-card-title class="mb-4">
                            <v-avatar color="primary" size="40" class="elevation-3 mr-4">
                                <v-icon dark>
                                mdi-image-multiple-outline
                                </v-icon>
                            </v-avatar>
                            <span class="headline">Opslag</span>
                        </v-card-title>    
                        <v-card-text>
                            <eod-storage-library @selected="selectionChanged" :searchFilters="uploadLibrarySearchFilters" :selected="selectedLibraryItems" :selectable="true" :uploadBtn="false" :colAttrs="{cols:12,sm:6,md:4,lg:2}"></eod-storage-library>
                        </v-card-text>
                        <v-card-actions class="grey lighten-3 py-3 px-3">
                            <v-spacer></v-spacer>
                            <v-btn text @click="uploadLibraryDialog = false" rounded>Annuleren</v-btn>
                            <v-btn color="secondary" depressed rounded @click="addSelection">
                                <v-icon left>mdi-plus-circle-outline</v-icon> Toevoegen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
            </v-list>
        </v-menu>
    </div>
    <v-row>
        <v-col v-bind="colAttrs" v-for="index in uploadItems" :key="index">
            <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col v-bind="colAttrs" v-for="(item, itemIndex) in items" :key="item.id">
            <eod-storage-object :selectable="selectable" :readonly="readonly" :hide-tags="hideTags" :hide-edit="hideEdit" @selectionChanged="objectSelectionChanged" :filters="filters" @tagClick="filterByTag" @imageClick="index = itemIndex" v-model="items[itemIndex]" @delete="deleteItem" @edit="editItem" @favorite="toggleFavorite"></eod-storage-object>
        </v-col>
    </v-row>
    <div class="text-center">
    <v-pagination
      v-model="page"
      :length="pageLength"
      circle
      v-if="pageLength > 1"
      :total-visible="10"
      class="mt-4"
    ></v-pagination>
    </div>

    <v-dialog v-model="deleteDialog" persistent max-width="550" v-if="activeItem">
            <v-card>
                <v-card-title class="mb-4">
                  <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-avatar><span class="headline">Verwijderen?</span>
          </v-card-title>
                <v-card-text>
                  <span class="text-body-1">Weet u zeker dat u '<strong>{{activeItem.name}}</strong>' wilt verwijderen?</span>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text @click="deleteDialog = false;activeItem=null;" rounded>Annuleren</v-btn>
                <v-btn color="error" depressed rounded @click="deleteItemAction">
                    <v-icon left>mdi-trash-can-outline</v-icon> Verwijderen
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <v-dialog v-model="editDialog" persistent max-width="750" v-if="activeItem">
            <v-card>
                <v-card-title>
                  <v-avatar color="primary" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-pencil-outline
            </v-icon>
          </v-avatar><span class="headline">Aanpassen</span>
          </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="5" class="d-flex align-center">
                            <v-img
                                :src="$eod.getImageUrl(activeItem.key)"
                                aspect-ratio="1.4"
                                contain
                            ></v-img>
                        </v-col>
                        <v-col cols="12" sm="7">
                            <v-divider vertical style="float:left;" class="mr-2"></v-divider>

                            <v-list two-line subheader>
                                <v-subheader>Systeem</v-subheader>
                                <eod-item-selector :tree="system_tree" v-model="system_tag"></eod-item-selector>
                                <v-subheader>Product</v-subheader>
                                <eod-item-selector :tree="product_tree" v-model="product_tag"></eod-item-selector>
                                <v-subheader>Tags</v-subheader>
                                <v-list>
                                    <v-list-item>
                                        <eod-listitem-autocomplete v-if="$eod.getOrganizationSetting('storageObjects.tags.listId')" v-model="listitem_tags" :listId="$eod.getOrganizationSetting('storageObjects.tags.listId').value" :multiple="true" :returnObject="true"></eod-listitem-autocomplete>
                                    </v-list-item>
                                </v-list>
                                
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text @click="editDialog = false;activeItem=null;" rounded>Annuleren</v-btn>
                <v-btn color="secondary" depressed rounded @click="editItemAction" :loading="editItemLoading">
                    <v-icon left>mdi-content-save</v-icon> Opslaan
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</div>
</template>
<script>
import eodStorageObject from './eod-storage-object';
import CoolLightBox from 'vue-cool-lightbox';
import listItemAutocomplete from './eod-list-item-autocomplete';
import eod_item_selector from './eod-item-selector';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import eod_search from './eod-search';
import StorageObject from './../models/storageObject';
import _ from 'lodash';
import User from './../models/user';

export default {
    name: 'eod-storage-library',
    components:{
        'eod-storage-object': eodStorageObject,
        'CoolLightBox': CoolLightBox,
        'eod-item-selector': eod_item_selector,
        'eod-listitem-autocomplete': listItemAutocomplete,
        'eod-search': eod_search,
    },
    props:{
        tags: {
            type: Object | Array,
            default: () => ({})
        },
        searchable: {
            type: Boolean,
            default: true
        },
        filterable: {
            type: Boolean,
            default: true
        },
        storageObjects:{
            type: Array,
            default: null
        },
        uploadLibrary: {
            type: Boolean,
            default: true
        },
        uploadComputer: {
            type: Boolean,
            default: true
        },
        uploadBtn: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        searchFilters: {
            type: Array,
            default: () => {
                return []
            }
        },
        uploadLibrarySearchFilters: {
            type: Array,
            default: () => {
                return []
            }
        },
        selected: {
            type: Array,
            default: () => {
                return []
            }
        },
        defaultVariables: {
            type: Object,
            default: () => {
                return {}
            }
        },
        selectable:{
            type:Boolean,
            default:false
        },
        hideTags:{
            type: Boolean,
            default: false
        },
        hideEdit:{
            type: Boolean,
            default: false
        },
        itemsPerPage: {
            type: Number,
            default: 40
        },
        colAttrs:{
            type: Object,
            default: () => {
                return {
                    cols:"6",
                    sm:"6",
                    md:"4",
                    lg:"3",
                    xl:"2"
                };
            }
        }
    },
    data: () => ({
        uploadItems: 0,
        deleteDialog: false,
        editDialog: false,
        activeItem: null,
        editItemLoading: false,
        index: null,
        system_tag: [],
        system_tree: ['companies', 'projects', 'tasks'],
        product_tag: [],
        product_tree: ['productTypes','products'],
        listitem_tags: [],
        filters: [],
        loading: false,
        page: 1,
        pageLength: 0,
        update: 0,
        items: [],
        uploadLibraryDialog: false,
        selectedLibraryItems: [],
        config: {
            search: StorageObject.searchConfig,
        }
    }),
    mounted(){
        this.initFilters(this.searchFilters);
        this.getDataFromApi();
    },
    watch: {
        searchFilters(value){
            this.initFilters(value);
            this.getDataFromApi();
        },
        storageObjects(value){
            this.getDataFromApi();
        },
        page(value){
            this.getDataFromApi();
        }
    },
    methods:{
        initFilters(filters){
            this.filters = [];
            if (filters.length > 0) {
                for (let i = 0; i < filters.length; i++) {
                    const filter = filters[i];
                    this.enableFilter(filter.field, filter);
                }
            }
        },
        toggleFavorite(item){
            const fav = item.hasOwnProperty('isFavorite') && item.isFavorite == true?false:true;
            this.$eod.save('StorageObject', {
                id: item.id,
                isFavorite: fav,
                type: item.type
            }).then(() => {
                this.updateStorageObject(item.id);
            });
        },
        objectSelectionChanged(item, selected){
            if (selected) {
                this.selectedLibraryItems.push(item.id); 
            }else{
                for (let i = 0; i < this.selectedLibraryItems.length; i++) {
                    const key = this.selectedLibraryItems[i];
                    if (key == item.id) {
                        this.selectedLibraryItems.splice(i, 1);
                        break;
                    }
                }
            }

            this.$emit('selected', this.selectedLibraryItems);
        },
        selectionChanged(items){
            this.selectedLibraryItems = items;
        },
        addSelection(){
            for (let i = 0; i < this.selectedLibraryItems.length; i++) {
                const itemId = this.selectedLibraryItems[i];
                
                this.uploadItems++;

                this.$eod.createStorageObject(itemId, this.tags).then(result => {
                    if(!this.storageObjects){
                        if (!this.items) {
                            this.items = [];
                        }
                        this.items.unshift(result.data.storageObject);
                    }
                    this.$emit('added', result.data.storageObject);
                }).catch(err => {
                    // Show error if upload went wrong
                }).finally(() => {
                    this.uploadItems--;
                });
            }

            this.uploadLibraryDialog = false;
            this.selectedLibraryItems = [];
        },
        getStorageObjects(){
            let items = [];

            if (this.items) {
                for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    items.push({
                        title: item.name,
                        description: item.description,
                        src: this.$eod.getImageUrl(item.key),
                    });
                }
            }

            return items;
        },
        filterByTag(tag, isSystemTag){
            if (isSystemTag) {
                this.toggleFilter(tag.filter, tag);
            }
        },
        toggleFilter(field, item){
            if(this.filterable){
                let filterPos = this.getFilterPos(field, item.value);
                if(filterPos != null){
                    this.filters.splice(filterPos, 1);
                }else if(item.value != null){
                    this.enableFilter(field, item);
                }

                this.page = 1;
                this.getDataFromApi();
            }
        },
        enableFilter(field, item){
            let config = null;
            for (let i = 0; i < this.config.search.length; i++) {
                const search = this.config.search[i];
                if(search.filter == field){
                    config = search;
                    break;
                }
            }

            this.filters.push({
                config: config,
                result: {
                    id: item.value,
                    search_title: item.name
                }
            });
        },
        getFilterPos(field, id){
            for (let i = 0; i < this.filters.length; i++) {
                const filter = this.filters[i];
                if (filter.config.filter == field) {
                    if(typeof id == 'undefined'){
                        return i;
                    }

                    if (id == filter.result.id) {
                        return i;
                    }
                }
            }

            return null;
        },
        getDataFromApi(){

            this.loading = true;

            let variables = _.merge({
                offset: this.page-1,
                limit: this.itemsPerPage,
                orderBy: {
                    column: 'createdAt',
                    type: 'desc'
                }
            }, this.$helper.filtersToVariables(this.filters), this.defaultVariables);

            if(this.storageObjects && Array.isArray(this.storageObjects)){
                variables.whereIn = {
                    column: 'id',
                    array: this.storageObjects
                }
            }

            this.$eod.get('storageObjects', ['id', 'key', 'isFavorite', 'name', 'type', 'createdAt', 'task{id name}', 'taskType{id name}', 'project{id name}', 'company{id name}', 'measurementType{id name}', 'service{id name}', 'serviceType{id name}', 'product{id name}', 'productType{id name}', 'tags {id name}', 'user{id}'], variables).then(result => {
                this.pageLength = Math.ceil(result.data.data.storageObjects.totalCount/this.itemsPerPage);
                return result.data.data.storageObjects.edges
                
            }).then(storageObjects => {
                const resolveUserIds = [];
                for (let i = 0; i < storageObjects.length; i++) {
                    const storageObject = storageObjects[i];
                    if(storageObject.user){
                        resolveUserIds.push(storageObject.user.id);
                    }
                }

                const usersById = {};
                if(resolveUserIds[0]){
                    return this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username'], {
                        whereIn:[{
                            column: 'id',
                            array: resolveUserIds
                        }]
                    }).then(response => {
                        
                        if(response && response.data.data && response.data.data.resolveUsers){
                            for (let i = 0; i < response.data.data.resolveUsers.edges.length; i++) {
                                const user = response.data.data.resolveUsers.edges[i];
                                usersById[user.id] = new User(user);
                            }
                        }

                        return {items: storageObjects, users: usersById}
                    }).catch(() => {
                        return {items: storageObjects, users: usersById}
                    })
                }

                return {items: storageObjects, users: usersById}
            }).then(({items, users}) => {

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if(item.user && users[item.user.id]){
                        item.user = users[item.user.id];
                    }
                }

                console.log('items', items);

                this.items = items;
                
                this.loading = false;
                this.update++;
            });
        },
        uploadFiles(e){
            let files = e.target.files;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                this.uploadItems++;

                const tags = this.tags;
                if(!tags){
                    tags = {};
                }

                if(!tags.userId){
                    tags.userId = this.$eod.getUser().id;
                }

                console.log('upload object', tags);
                
                let data = {
                    tags: JSON.stringify(tags),
                    storageObject: file
                };

                this.$eod.uploadStorageObject(data).then(result => {
                    if(!this.storageObjects){
                        if (!this.items) {
                            this.items = [];
                        }
                        this.items.unshift(result.data.storageObject);
                    }
                    this.$emit('added', result.data.storageObject);
                }).catch(err => {
                    // Show error if upload went wrong
                }).finally(() => {
                    this.uploadItems--;
                });
 
            }
        },
        addItem(){
            this.$refs.newFile.click();
        },
        deleteItem(item){
            this.activeItem = item;
            this.deleteDialog = true;
        },
        deleteItemAction(){
            this.$eod.deleteStorageObject(this.activeItem.id).then(result => {
                    for (let i = 0; i < this.items.length; i++) {
                        const storageObject = this.items[i];
                        if (storageObject.id == this.activeItem.id) {
                            this.items.splice(i, 1);
                        }
                    }

                    this.$emit('updated', this.items);
                }).finally(() => {
                this.deleteDialog = false;
                this.activeItem = null;
                this.update++;
            });
            
        },
        editItem(item){

            this.$eod.getById('storageObject', item.id, ['id isActive isFavorite type key name product{id name} productType{id name} project{id name} company{id name} service{id name} serviceType{id name} task{id name} taskType{id name} measurementType{id name} tags{id name}'])
                .then(result => {
                    let item = result.data.data.storageObject;

                    this.system_tag = [];
                    if (item.task) {
                        this.system_tag = [
                            {
                                id: item.task.id,
                                name: item.task.name,
                                level: 2
                            }
                        ]
                    }else if (item.project) {
                        this.system_tag = [
                            {
                                id: item.project.id,
                                name: item.project.name,
                                level: 1
                            }
                        ]
                    }else if (item.company) {
                        this.system_tag = [
                            {
                                id: item.company.id,
                                name: item.company.name,
                                level: 0
                            }
                        ]
                    }

                    this.product_tag = [];
                    if (item.productType) {
                        this.product_tag = [
                            {
                                id: item.productType.id,
                                name: item.productType.name,
                                level: 0
                            }
                        ]
                    }

                    
                    if (item.product) {
                        this.product_tag = [
                            {
                                id: item.product.id,
                                name: item.product.name,
                                level: 1
                            }
                        ]
                    }

                    if (item.tags) {
                        this.listitem_tags = item.tags;
                    }

                    this.activeItem = item;
                    this.editDialog = true;
                });

            
        },
        updateStorageObject(itemId){
            this.$eod.getById('storageObject', itemId, ['id isActive isFavorite type key name product{id name} productType{id name} project{id name} company{id name} service{id name} serviceType{id name} task{id name} taskType{id name} measurementType{id name} tags{id name}'])
                .then(result => {
                    let item = result.data.data.storageObject;
                    for (let i = 0; i < this.items.length; i++) {
                        if (this.items[i].id == item.id) {
                            this.items[i] = item;
                            this.$forceUpdate();
                            return;
                        }
                    }
                });
        },
        async editItemAction(){

            this.editItemLoading = true;

            let data = {
                id: this.activeItem.id,
                tags: [],
                companyId: null,
                projectId: null,
                taskId: null,
                productTypeId: null,
                productId: null,
                type: this.activeItem.type
            };

            for (let i = 0; i < this.listitem_tags.length; i++) {
                const tag = this.listitem_tags[i];
                data.tags.push({id: tag.id});
            }

            if (this.system_tag[0]) {
                let system_tags = await this.getItemParentTags(this.system_tree, this.system_tag[0]);
                data = this.$helper.mergeObjects(data, system_tags);
            }

            if (this.product_tag[0]) {
                let product_tags = await this.getItemParentTags(this.product_tree, this.product_tag[0]);
                data = this.$helper.mergeObjects(data, product_tags);
            }

            this.$eod.save('StorageObject', data).then(() => {
                this.updateStorageObject(data.id);
            });

            this.editItemLoading = false;
            this.editDialog = false;
            this.system_tag = [];
            this.product_tag = [];
            this.listitem_tags = [];
            this.activeItem = null;
        },
        getItemParentTags(tree, item){

            let modules = require('./../classes/settings').modules;
            let system_module = modules[tree[item.level]];

            let parent_query = '';
            for (let i = 1; i <= item.level; i++) {
                let parentfield = modules[tree[i]].parents[tree[i-1]];
                parent_query = parentfield+'{id '+parent_query+'}';
            }

            return this.$eod.getById(system_module.item, item.id, ['id', parent_query])
                .then(result => {
                    let result_item = result.data.data[system_module.item];

                    let tags = {};
                    tags[system_module.key] = result_item.id;
                    for (let i = item.level; i > 0; i--) {
                        let parentfield = modules[tree[i]].parents[tree[i-1]];
                        result_item = result_item[parentfield];
                        tags[modules[tree[i-1]].key] = result_item.id;
                    }

                    return tags;

                });
        }
    }
}
</script>