const { default: Workflow } = require('../../eodvuecomponents/src/models/workflow');
const { default: Task } = require('../../eodvuecomponents/src/models/task');
const { default: JobType } = require('../../eodvuecomponents/src/models/jobType');
const { default: TaskType } = require('../../eodvuecomponents/src/models/taskType');

let settings = require('@/eodvuecomponents/src/classes/settings');

module.exports = {
    routes: [{
        path: '/tasks',
        name: 'tasks',
        meta:{
            permissions:['tasks.workflows.read', 'tasks.jobs.read', 'tasks.planning.read']
        },
        redirect: { name: 'tasks.overview' }
    },
    {
        path: '/tasks/jobs',
        name: 'tasks.jobs',
        meta:{
            permissions:['tasks.jobs.read']
        },
        component: () => import('./views/jobs.vue')
    },
    {
        path: '/tasks/overview',
        name: 'tasks.overview',
        meta:{
            permissions:['tasks.workflows.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/tasks/planning',
        name: 'tasks.planning',
        meta:{
            permissions:['tasks.planning.read']
        },
        component: () => import('./views/planning.vue')
    },
    {
        path: '/tasks/routes',
        name: 'tasks.routes',
        meta:{
            permissions:['routes.read']
        },
        component: () => import('./views/routes.vue')
    },
    {
        path: '/tasks/workflows',
        name: 'taskWorkflows',
        meta:{
            permissions:['tasktypes.read']
        },
        component: () => import('./workflows/index.vue')
    },
    {
        path: '/tasks/workflows/detail/:id?',
        name: 'taskWorkflow',
        meta:{
            permissions:['tasktypes.create','tasktypes.update']
        },
        component: () => import('./workflows/detail.vue')
    },
    {
        path: '/tasks/jobtypes',
        name: 'jobTypes',
        meta:{
            permissions:['jobtypes.read']
        },
        component: () => import('./jobtypes/index.vue')
    },
    {
        path: '/tasks/jobtypes/detail/:id?',
        name: 'taskForm',
        meta:{
            permissions:['jobtypes.create', 'jobtypes.update']
        },
        component: () => import('./jobtypes/detail.vue')
    },
    {
        path: '/tasks/response/:id?',
        name: 'response',
        component: () => import('./responses/detail.vue')
    }
    ],
    objectType: 'TASK',
    search: Workflow.searchConfig,
    planning: {
        users: {
            search: [
                /*{
                    'icon': 'mdi-account-outline',
                    'label': 'Gebruiker',
                    'type': 'module',
                    'settings': {
                        'module': 'searchUsers',
                        'fields': ['firstName', 'lastName'],
                        'title': '{firstName} {lastName}',
                        'options': {
                            enabled: true
                        }
                    },
                    'filter': 'id'
                },*/
                {
                    icon: 'mdi-account-group-outline',
                    label: 'Team',
                    type: 'relation',
                    settings: {
                        id: 'id',
                        module: "groups",
                        fields: ['name'],
                        title: '{name}',
                    },
                    filter: 'groups'
                },
            ]
        },
        projects: {
            search: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Omschrijving',
                'type': 'property',
                'filter': 'description'
            },
            {
                'icon': 'mdi-office-building-outline',
                'label': 'Bedrijf',
                'type': 'module',
                'settings': {
                    'module': 'companies',
                    'fields': ['name'],
                    'title': '{name}',
                },

                'filter': 'companyId'
            }
            ]
        }
    },
    calendar: [
        {
            'icon': 'mdi-clipboard-outline',
            'label': 'Workflow',
            'type': 'module',
            'settings': {
                'module': 'taskTypes',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'taskTypeId'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'type': 'module',
            'settings': {
                'module': 'projects',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'projectId'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'type': 'module',
            'settings': {
                'module': 'services',
                'fields': ['name'],
                'title': '{name}',
            },

            'filter': 'serviceId'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'module',
            'settings': {
                'module': 'companies',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'companyId'
        },
        {
            'icon': 'mdi-tag-outline',
            'label': 'Status',
            'type': 'options',
            'settings': {
                'options': () => {
                    let statusCodes = [];
                    for (let i = 0; i < settings.statusCodes.length; i++) {
                        const statusCode = settings.statusCodes[i];
                        if (statusCode.job_enabled || statusCode.value == 'done') {
                            statusCodes.push(statusCode);
                        }
                    }
                    return statusCodes;
                }
            },
            'filter': 'statusCode'
        },
        /*{
            'icon': 'mdi-calendar',
            'label': 'Planningsdatum',
            'type': 'date-between',
            'filter': 'dueRequestDate'
        },*/
        {
            'icon': 'mdi-account-outline',
            'label': 'Verantwoordelijke',
            'type': 'relation',
            'settings': {
                'module': 'searchUsers',
                'fields': ['firstName', 'lastName', 'username'],
                'title': '{firstName} {lastName} ({username})',
                'options': {
                    enabled: true
                }
            },
            'filter': 'responsibleUsers'
        },
    ],
    detail: {
        route: 'task',
        endpoint: 'task',
        objectName: 'Task',
        fields: ['id', 'name', 'description', 'statusCode', 'dueBeforeDate', 'dueAfterDate', 'completedDate', 'dueRequestDate', 'postalCode', 'company{id}', 'taskType{id}', 'service{id}', 'project{id}', 'storageObjects{ id isActive key name product{id name} project{id name} company{id name} service{id name} serviceType{id name} task{id name} taskType{id name} measurementType{id name} type tags{id name}}', 'products{id name description code quantity stockLocation{id}}', 'users{id username firstName lastName assignedRealmRoles{name}}'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        }, {
            tab: 'storageobjects',
            text: 'Media'
        }]
    },
    index: {
        route: 'tasks',
        endpoint: 'tasks',
        fields: ['id', 'name', 'isJobActive', 'stepName', 'stepType', 'stepId', 'description', 'isCompleted', 'reportedAt', 'postalCode', 'parent{id}', 'taskType{id name}', 'templates{id type object content}', 'company{id name}', 'project{id name description}', 'children{id responses{id} category}', 'service{id name periodicityCode}', 'statusCode', 'dueRequestDate', 'dueAfterDate', 'dueBeforeDate', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: '',
                width: 30,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-expand-icon.vue'),
                extra: {
                    inset(item) {
                        if (item.parent && item.parent.id) {
                            return 12;
                        }

                        return 0;
                    }
                }
            },
            {
                text: 'Naam',
                value: 'name',
                extra: {
                    chars: 60,
                    description: {
                        value: 'description',
                    }
                },
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
            },
            {
                text: 'Huidige stap',
                value: 'currentStep',
                sortable: false,
                component: () => import('./columns/workflow-step.vue')
            },
            {
                text: 'Postcode',
                value: 'postalCode',
                width: 100,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
            },
            {
                text: 'Planning',
                value: 'dueRequestDate',
                sortable: true,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-planning.vue'),
                extra: {
                    icon: 'mdi-calendar',
                    collapsed: false,
                }
            },
            {
                text: 'Project',
                value: 'project',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-project.vue'),
                extra: {
                    icon: 'mdi-folder-open-outline',
                    collapsed: false,
                }
            },
            {
                text: 'Bedrijf',
                value: 'company',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-company.vue'),
                extra: {
                    icon: 'mdi-office-building-outline',
                    collapsed: false,
                }
            },
            {
                text: 'Dienst',
                value: 'service',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-service.vue'),
                extra: {
                    icon: 'mdi-file-sign',
                    collapsed: false,
                }
            },
            {
                text: 'Status',
                value: 'statusCode',
                sortable: true,
                width: 160,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-status-code.vue'),
                extra: {
                    readonly: true,
                }
            },
            {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                text: '',
                width: 50,
                value: 'reportedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-clock-check-outline',
                    collapsed: true,
                    prepend: 'Uitgevoerd op'
                }
            },
            {
                width: 100,
                sortable: false,
                component: () => import('./columns/actions.vue')
            }
        ],
        expanded: {
            component: () => import('./rows/jobs.vue')
        },
        tabs: [],
        picklist: true
    },
    jobs: {
        objectType: 'JOB',
        searchTemplateType: 'JOB_FILTER',
        search: Task.searchConfig,
        itemObject: Task,
        index: {
            filterName: 'jobs',
            route: 'jobs',
            endpoint: 'tasks',
            fields: ['id', 'name', 'isJobActive', 'stepName', 'responses{id}', 'stepId', 'parent{id name postalCode templates{id type object name content} taskType{id name} company{id name} project{id name} service{id name}}', 'description', 'isCompleted', 'company{id name}', 'project{id name description}', 'service{id name periodicityCode}', 'statusCode', 'dueRequestDate', 'dueAfterDate', 'dueBeforeDate', 'createdAt', 'updatedAt', 'responsibleUsers{id}'],
            headers: [
                {
                    text: 'Naam',
                    value: 'parent.name',
                    sortable: false,
                    extra: {
                        chars: 60,
                        description: {
                            value: 'description',
                            /*extra: {
                                chars: 100
                            }*/
                        }
                    },
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
                },
                {
                    text: 'Huidige stap',
                    sortable: false,
                    component: () => import('./columns/workflow-step.vue'),
                    extra: {
                        getTemplates(item) {
                            return item.parent.templates;
                        }
                    }
                },
                {
                    text: 'Postcode',
                    value: 'parent.postalCode',
                    sortable: false,
                    width: 50,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
                },
                {
                    text: 'Planning',
                    value: 'dueRequestDate',

                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-planning.vue'),
                    extra: {
                        icon: 'mdi-calendar',
                        collapsed: false,
                        getTemplates(item) {
                            return item.parent.templates;
                        }
                    }
                },
                {
                    text: 'Project',
                    value: 'project',
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-project.vue'),
                    extra: {
                        icon: 'mdi-folder-open-outline',
                        collapsed: false,
                    }
                },
                {
                    text: 'Bedrijf',
                    value: 'company',
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-company.vue'),
                    extra: {
                        icon: 'mdi-office-building-outline',
                        collapsed: false,
                    }
                },
                {
                    text: 'Dienst',
                    value: 'service',
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-service.vue'),
                    extra: {
                        icon: 'mdi-file-sign',
                        collapsed: false,
                    }
                },
                {
                    text: 'Status',
                    value: 'statusCode',
                    sortable: true,
                    width: 160,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-status-code.vue'),
                    extra: {
                        readonly: true,
                    }
                },
                {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                },
                {
                    width: 80,
                    sortable: false,
                    extra: {
                        hideMobileLink: true,
                        hideDelete: true
                    },
                    component: () => import('./columns/actions.vue')
                }
            ],
            expanded: {
                component: () => import('./rows/responses.vue')
            },
            tabs: [],
            picklist: false
        }
    },
    export: {
        endpoint: 'tasks',
        fields: ['id', 'name', 'description', 'statusCode', 'createdAt', 'updatedAt', 'project{name}']
    },
    import: {
        endpoint: 'Tasks',
        objectName: 'Task',
        fields: ['id', 'name', 'description', 'statusCode']
    },
    itemObject: Workflow,
    workflows: {
        search: [{
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        }],
        index: {
            route: 'taskWorkflows',
            endpoint: 'taskTypes',
            fields: ['id', 'name', 'defaultProject{id name}', 'defaultService{id name}', 'configuration{version settings{key value label isDefault isRoot}}', 'createdAt', 'updatedAt'],
            headers: [
                {
                    width: 50,
                    sortable: false,
                    component: () => import('./workflows/columns/color.vue')
                },
                {
                    text: 'Naam',
                    value: 'name',
                    extra: {
                        chars: 60,
                        description: {
                            value: 'description',
                        }
                    },
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link')
                },
                {
                    text: 'Standaard project',
                    value: 'defaultProject.name',
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link')
                },
                {
                    text: 'Standaard dienst',
                    value: 'defaultService.name',
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 160,
                    sortable: false,
                    component: () => import('./workflows/columns/actions.vue')
                }
            ],
            tabs: [],
        },
        itemObject: TaskType,
        detail: {
            route: 'taskWorkflow',
            endpoint: 'taskType',
            objectName: 'TaskType',
            fields: ['id', 'name', 'description', 'estimatedDuration', 'defaultProject{id}', 'defaultService{id}', 'measurementTypes{id name description formConfig{enabled category subCategory}}', 'templates{id name type object content}', 'defaultProducts{id productType{id unit{id name}} name description code quantity stockLocation{id}}', 'configuration{version settings{key value label isDefault isRoot}}'],
            tabs: [{
                tab: 'general',
                text: 'Algemeen'
            },
            {
                tab: 'products',
                text: 'Materiaal',
            },
            {
                tab: 'form',
                text: 'Stappen',
            }
            ],
        },
        export: {
            endpoint: 'taskTypes',
            fields: ['id', 'name', 'description']
        },
        import: {
            endpoint: 'taskTypes',
            objectName: 'TaskType',
        },

    },
    jobtypes: {
        search: [{
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        }],
        index: {
            route: 'jobTypes',
            endpoint: 'jobTypes',
            fields: ['id', 'name', 'defaultProject{id name}', 'defaultService{id name}', 'createdAt', 'updatedAt', 'configuration{version settings{key value label isDefault isRoot}}'],
            headers: [{
                width: 50,
                sortable: false,
                component: () => import('./jobtypes/columns/color.vue')
            },
            {
                text: 'Naam',
                value: 'name',
                extra: {
                    description: {
                        value: 'description',
                        extra: {
                            chars: 100
                        }
                    }
                },
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link')
            },
            {
                text: 'Standaard project',
                value: 'defaultProject.name',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link')
            },
            {
                text: 'Standaard dienst',
                value: 'defaultService.name',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            }, {
                width: 160,
                sortable: false,
                component: () => import('./jobtypes/columns/actions'),
                extra: {
                    permissions: {
                        update: 'jobtypes.update',
                        delete: 'jobtypes.delete'
                    }
                }
            }
            ],
            tabs: [],
        },
        itemObject: JobType,
        detail: {
            route: 'taskForm',
            endpoint: 'jobType',
            objectName: 'JobType',
            fields: ['id', 'name', 'description', 'reportListItem{id}', 'isConnectable', 'connectableConfig{content}', 'skills{id name threshold}', 'defaultProject{id}', 'defaultService{id}', 'measurementTypes{id name description formConfig{enabled category subCategory}}', 'templates{id name type object content}', 'defaultProductTypes{id name description quantity unit{id name}}', 'defaultProducts{id name description code quantity productType{unit{id name}}}', 'configuration{version settings{key value label isDefault isRoot}}'],
            tabs: [{
                tab: 'general',
                text: 'Algemeen'
            },
            {
                tab: 'products',
                text: 'Materiaal',
            },
            {
                tab: 'form',
                text: 'Formulier',
            }
            ],
        },
        export: {
            endpoint: 'jobTypes',
            fields: ['id', 'name', 'description']
        },
        import: {
            endpoint: 'jobTypes',
            objectName: 'JobType',
        },

    }
}