<template>
    <div>    
    <v-row>
              <v-col cols="12" :sm="!isAdvanced?12:8" :lg="!isAdvanced?12:9">
                  <eod-card class="mb-4" :outlined="compact">
        <v-card-text>
                <v-row>
                    <v-col>
                        <eod-text-field
                        :value="value.name"
                        @input="val => updateProperty('name', val)"
                            v-model="value.name"
                            :rules="[val => $validator.required(val, 'Naam')]"
                            label="Naam*"
                            required
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field
                        :value="value.description"
                        @input="val => updateProperty('description', val)"
                            label="Omschrijving"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-autocomplete
                        :disabled="value.type == 'INTERNAL'"
                        :items="companyTypes"
                        label="Type*"
                        :value="value.type"
                        @input="val => updateProperty('type', val)"
                        item-text="text"
                        item-value="value"
                        :rules="[val => $validator.required(val, 'Type')]"
                        hide-details
                        outlined
                        ></eod-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-autocomplete :rules="serviceRule?[val => $validator.required(val, 'Standaard dienst')]:[]" item-value="id" item-text="name" outlined hide-details label="Standaard dienst*" return-object :items="services" :value="value.defaultService"  @input="val => updateProperty('defaultService', val)">
                            <template v-slot:append-item>
                                <v-divider class="mb-2"></v-divider>
                                <v-list-item id="add_service" @click.stop="isCreateServiceDialogVisible = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Nieuwe dienst aanmaken</v-list-item-title>
                                        <v-list-item-subtitle>Maak een dienst aan en voeg deze toe aan het bedrijf</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </eod-autocomplete>
                        <eod-dialog v-if="value" v-model="isCreateServiceDialogVisible" title="Dienst toevoegen" icon="mdi-plus" iconColor="primary" width="1200" @okAction="createServiceAction" okText="Toevoegen">
                            <eod-service-detail class="mt-4" outlined compact v-model="createService" hide-company></eod-service-detail>
                        </eod-dialog>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isAdvanced" @click="isAdvanced = true" text small>Geavanceerd <v-icon right small>mdi-chevron-down</v-icon></v-btn>
                </v-row>
                
        </v-card-text>
    </eod-card>
    <eod-card class="mb-4" v-if="isAdvanced" :outlined="compact">
        <v-card-text>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.nationalIdentificationNumber"
                            label="Nationaal identificatienummer"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field
                            v-model="value.vatNumber"
                            label="BTW-nummer"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    </v-row>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.iban"
                            label="IBAN"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field
                            v-model="value.bic"
                            label="BIC"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.website"
                            label="Website"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-listitem-autocomplete dense outlined v-if="$eod.getOrganizationSetting('companies.divisions.listId')" v-model="value.division" label="Divisie" returnObject nullable :listId="$eod.getOrganizationSetting('companies.divisions.listId').value"></eod-listitem-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-listitem-autocomplete dense outlined v-if="$eod.getOrganizationSetting('companies.departments.listId')" v-model="value.department" label="Departement" returnObject nullable :listId="$eod.getOrganizationSetting('companies.departments.listId').value"></eod-listitem-autocomplete>
                    </v-col>
                </v-row>
        </v-card-text>
    </eod-card>
    <eod-card v-if="isAdvanced" :outlined="compact">
                      <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                                <eod-color-picker outlined v-if="value.configuration && $helper.getConfigurationSetting(value.configuration.settings, 'color_primary')" v-model="$helper.getConfigurationSetting(value.configuration.settings, 'color_primary').value" label="Primair kleur" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <eod-color-picker outlined v-if="value.configuration && $helper.getConfigurationSetting(value.configuration.settings, 'color_secundary')" v-model="$helper.getConfigurationSetting(value.configuration.settings, 'color_secundary').value" label="Secundair kleur" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                            </v-col>
                          </v-row>
                      </v-card-text>
                      <v-card-actions></v-card-actions>
                  </eod-card>
              </v-col>
              <v-col v-if="isAdvanced" cols="12" sm="4" lg="3">
                  <v-card class="mb-4" :outlined="compact">
                      <v-card-text>
                            <label class="text-subtitle-1">Logo</label>
                            <eod-storage-library v-if="value && value.id" @added="logoAdded" :storageObjects="logo" :tags="{companyId:value.id}" :filterable="false" :searchable="false" :colAttrs="{cols:12}"></eod-storage-library>
                            <p v-else>Gelieve op te slaan om media toe te voegen.</p>
                      </v-card-text>
                      <v-card-actions></v-card-actions>
                  </v-card>
                  <v-card class="mb-4" v-if="sharepoint" :outlined="compact">
                      <v-card-text>
                            <label class="text-subtitle-1">Koppelingen</label>
                            <v-row class="mt-2">
                                <v-col cols="4" lg="2">
                                    <a target="_blank" :href="sharepoint"><v-img :src="require('@/assets/img/integrations/sharepoint.svg')" contain/></a>
                                </v-col>
                            </v-row>
                      </v-card-text>
                      <v-card-actions></v-card-actions>
                  </v-card>
                  <v-card class="mb-4" :outlined="compact">
                      <v-card-text>
                          <label class="text-subtitle-1">Tags</label>
                          <eod-tags deletable addable v-model="value.tags"></eod-tags>
                      </v-card-text>
                  </v-card>
                  <v-card :outlined="compact">
                      <v-card-text>
                          <v-textarea rows="6" label="Opmerkingen" outlined hide-details v-model="value.remarks"></v-textarea>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
        </div>
</template>
<script>
import eodListitemAutocomplete from './eod-list-item-autocomplete.vue';
import eodTags from './eod-tags.vue';
import eodColorPicker from './eod-color-picker.vue';
import eodStorageLibrary from './eod-storage-library.vue';
import eodServiceDetail from './eod-service-detail.vue'
import eodDialog from './eod-dialog.vue'
import Company from './../models/company.js';
import _ from 'lodash';

export default {
    name: 'eod-company-detail',
    components:{
        eodListitemAutocomplete,
        eodTags,
        eodColorPicker,
        eodStorageLibrary,
        eodServiceDetail,
        eodDialog,
    },
    props:{
        value: {
            type: Object,
            default: () => ({})
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            services: [],
            createService: {id:1},
            isAdvanced: false,
            companyTypes: [],
            isCreateServiceDialogVisible:false,
            sharepoint: null,
            serviceRule: true,
            Company:Company
        }
    },
    computed: {
      logo(){
        if(this.value.configuration){
            let setting = this.$helper.getConfigurationSetting(this.value.configuration.settings, 'logo');
            if(setting != null && setting.value && setting.value != ''){
                return [setting.value];
            }
        }
          
          
          return [];
      }
    },
    created(){
        this.isAdvanced = !this.compact;
        this.companyTypes = _.clone(Company.companyTypes);
    },
    mounted(){
        let sharepointURLSetting = this.$eod.getOrganizationSetting('share.sharepoint.companies.url');
        if(sharepointURLSetting && sharepointURLSetting.value){
            this.sharepoint = this.$helper.replaceTags(sharepointURLSetting.value, {
                company: this.value
            });
        }

        if (!this.value.configuration) {
            const config = {
                version: '1.0',
                settings: [
                    {
                        key: 'logo',
                        value: '',
                        label: 'Logo'
                    },
                    {
                        key: 'color_primary',
                        value: '#031859',
                        label: 'Primair kleur'
                    },
                    {
                        key: 'color_secundary',
                        value: '#f23d3d',
                        label: 'Secundair kleur'
                    }
                ]
            }

            this.updateProperty('configuration', config);
        }

          if(this.value.id){
            if(this.value.type == 'INTERNAL'){
                this.companyTypes.push({
                    value: 'INTERNAL',
                    text: 'Intern'
                });
            }

            this.$eod.get('services', ['id', 'name'], {
                where: [
                    {
                        'column': 'companyId',
                        'operator': '=',
                        'value': this.value.id
                    }
                ]
            }).then(response => {
                if(response.data.data.services){
                    this.services = response.data.data.services.edges;
                }
            });
          } else {
            const defaultService = {
                id: 2,
                name: 'Standaardcontract'
            }
            this.setService(defaultService);
          }
    },
    methods:{
        updateProperty(propertyName, value){
            const val = this.value;
            val[propertyName] = value;
            this.$emit('input', val);
        },
        logoAdded(object){
            this.$helper.getConfigurationSetting(this.value.configuration.settings, 'logo').value = object.id;
        },
        createServiceAction(){
            const serviceCopy = {...this.createService};
            this.setService(serviceCopy);
            this.isCreateServiceDialogVisible = false;
        },
        setService(service){
            const val = this.value;
            val.defaultService = service;
            this.services.push(service);
            this.$emit('input', val);
        }
    }
}
</script>