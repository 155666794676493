<template>
    <v-autocomplete ref="autocomplete" :menu-props="{closeOnContentClick:true}" :search-input.sync="search" @change="changed" :disabled="disabled" :placeholder="placeholder" hide-details :clearable="clearable" :loading="loading" :label="label" :multiple="multiple" :deletable-chips="multiple" :small-chips="multiple"  :return-object="returnObject" :value="value" :items="items" :dense="dense" :outlined="outlined" item-text="name" item-value="id" auto-select-first>
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-slot:no-data>
            <v-list-item v-if="addable" @click="addItem(search)">
                <v-list-item-content>
                    <v-list-item-title v-if="search && search != ''">'<strong v-html="search"></strong>' toevoegen</v-list-item-title>
                    <v-list-item-title v-else>Typ om een item te zoeken of toe te voegen...</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title v-html="searchText"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>
<script>
    export default {
        props:{
            label: String,
            prependItems: {
                type: Array,
                default: () => ([])
            },
            searchText: {
                type: String,
                default: 'Zoek een item...'
            },
            placeholder: {
                type: String,
                default: ''
            },
            value: Object|String,
            listId: String,
            fields: {
                type: Array,
                default: () => {
                    return ['id', 'name', 'description'];
                }
            },
            returnObject: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            dense: {
                type: Boolean,
                default: true
            },
            outlined: {
                type: Boolean,
                default: true
            },
            nullable: {
                type: Boolean,
                default: false
            },
            clearable:{
                type: Boolean,
                default: false
            },
            disabled:{
                type: Boolean,
                default: false
            },
            addable:{
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            search: '',
            items: [],
            loading: false
        }),
        mounted(){
            this.getDataFromApi();
        },
        methods: {
            changed(val){
                this.$emit('input', val);
                this.$emit('change', val);
            },
            getNextThreshold(){
                let max = 0;
                for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    if(max < item.threshold){
                        max = item.threshold;
                    }
                }
                return max+1;
            },
            addItem(name){
                this.loading = true;
                this.$eod.save('ListItem', {
                    listId: this.listId,
                    name: name,
                    threshold: this.getNextThreshold(),
                    isActive: true
                }, false, {
                    create:{
                        returnfields: ['id '+this.fields.join(' ')+' threshold isActive']
                    }
                }).then(response => {
                    if(response.data.data.createListItem){
                        const item = response.data.data.createListItem;
                        this.items.push(item);

                        if(this.returnObject){
                            this.changed(item);
                        }else{
                            this.changed(item.id);
                        }
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            checkItemDisabled(item){
                if(!item.isActive){
                    item.disabled = true;
                }
                return item;
            },
            getDataFromApi(){
                this.loading = true;
                this.$eod.get('listItems', [this.fields.join(' ')+' threshold isActive'], {
                    orderBy:{
                        column: 'name',
                        type: 'ASC'
                    },
                    where:[
                        {
                            column: 'listId',
                            operator: '=',
                            value: this.listId
                        }
                    ]
                })
                .then(result => {
                    let items = result.data.data.listItems.edges;

                    for (let i = 0; i < items.length; i++) {
                        const item = this.checkItemDisabled(items[i]);
                    }

                    if(this.nullable){
                        items.unshift({id:null,name:'N.V.T.',description:'Niet van toepassing'});
                    }
                    for (let i = 0; i < this.prependItems.length; i++) {
                        const item = this.prependItems[i];
                        items.unshift(item);
                    }

                    this.items = items;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>