<template>
<v-radio-group
class="mt-0"
              :value="value"
              @change="val => $emit('input', val)"
              row
              :disabled="readonly"
            >
              <v-radio
                label="Nee"
                color="error"
                :value="0"
              ></v-radio>
              <v-radio
                label="Ja"
                color="success"
                :value="1"
              ></v-radio>
            </v-radio-group>
</template>
<script>
export default {
    name: 'eod-measurement-yesno',
    props: {
        readonly: Boolean,
        value: Number
    }
}
</script>