import User from './user';

export default class Company {
    static fields = {
        name: String,
    }
    static companyTypes = [{
        value: 'CUSTOMER',
        text: 'Klant'
    }, {
        value: 'SUPPLIER',
        text: 'Leverancier'
    }, {
        value: 'CUSTOMER_SUPPLIER',
        text: 'Klant/Leverancier'
    }, {
        value: 'PRIVATE',
        text: 'Particulier'
    }];
    static exportFields = ['id', 'name', 'description', 'defaultService{name}', 'remarks', 'projects{name}', 'services{name}', 'type', 'vatNumber', 'website', 'iban', 'bic', 'division{name}', 'department{name}', 'nationalIdentificationNumber', 'users{id, firstName, lastName, username, email}, locations{name description streetAddress postalCode locality region country notes department}', 'groups{id name}'];
    static moduleName = 'companies';
    static searchConfig = [
        {
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        },
        {
            'icon': 'mdi-format-text',
            'label': 'Omschrijving',
            'type': 'property',
            'filter': 'description'
        },
        {
            'icon': 'mdi-tag-outline',
            'label': 'Type',
            'type': 'options',
            'settings': {
                'options': Company.companyTypes
            },
            'filter': 'type'
        },
        {
            'icon': 'mdi-alarm',
            'label': 'Wijzigingsdatum',
            'type': 'date-between',
            'filter': 'updatedAt'
        }
    ];
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    if (key == 'users' && item[key]) {
                        this[key] = [];
                        for (let i = 0; i < item[key].length; i++) {
                            this[key][i] = new User(item[key][i]);
                        }
                    } else {
                        this[key] = item[key];
                    }
                }
            }
        }
    }
    get contacts() {
        let settings = require('./../classes/settings');
        let contacts = [];
        if (this.users) {
            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].getRole() && settings.roles.contacts.includes(this.users[i].getRole().name)) {
                    contacts.push(this.users[i]);
                }
            }
        }

        return contacts;
    }
    get collaborators() {
        let settings = require('./../classes/settings');
        let collaborators = [];
        if (this.users) {
            for (let i = 0; i < this.users.length; i++) {

                if (this.users[i].getRole() && settings.roles.collaborators.includes(this.users[i].getRole().name)) {
                    collaborators.push(this.users[i]);
                }
            }
        }

        return collaborators;
    }
    getCompanyTypes() {
        return Company.companyTypes;
    }
    static getCompanyType(value) {
        for (let i = 0; i < Company.companyTypes.length; i++) {
            const type = Company.companyTypes[i];
            if (type.value == value) {
                return type;
            }
        }

        return null;
    }
    getSaveData() {
        let data = Object.assign({}, this);

        console.log('get save data');

        if (data.users) {
            for (let i = 0; i < data.users.length; i++) {
                data.users[i] = {
                    id: data.users[i].id,
                    organizationId: data.organizationId
                }
            }
        }

        if (data.groups) {
            for (let i = 0; i < data.groups.length; i++) {
                data.groups[i] = {
                    id: data.groups[i].id,
                    organizationId: data.organizationId
                }
            }
        }

        if (data.tags) {
            for (let i = 0; i < data.tags.length; i++) {
                data.tags[i] = {
                    id: data.tags[i].id,
                }
            }
        }

        data.divisionId = null;
        if (data.division) {
            data.divisionId = data.division.id;
        }
        delete data.division;

        if (data.hasOwnProperty('defaultService')) {
            data.defaultServiceId = null;
            if(data.defaultService.id && data.defaultService.id != 1){
                data.defaultServiceId = data.defaultService.id;
            }
            delete data.defaultService;
        }
        
        data.departmentId = null;
        if (data.department) {
            data.departmentId = data.department.id;
        }
        delete data.department;
        
        if(data.locations){
            for (let i = 0; i < data.locations.length; i++) {
                if (data.locations[i].department) {
                    data.locations[i].department = data.locations[i].department.id;
                }
                if(data.locations[i].lat){
                    data.locations[i].lat = parseFloat(data.locations[i].lat);
                }
                if(data.locations[i].lon){
                    data.locations[i].lon = parseFloat(data.locations[i].lon);
                }
            }
        }

        delete data.projects;
        delete data.services;

        return data;
    }
}
